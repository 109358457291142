import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FController from "./FControllerHook";

export default function FSelect({
  value,
  label,
  controller,
  onChange,
  collection,
  disabled = false,
  margin = "none",
  placeHolder = "",
  ...rest
}) {
  if (!controller) {
    controller = FController();
  }

  // Inicia o controller com as configurações default
  controller.init(
    controller,
    value !== null && value !== undefined
      ? collection.find((x) => x.id === value.id)
      : value
  );

  const validPlaceHolder = () => {
    return (
      placeHolder !== undefined && placeHolder !== null && placeHolder !== ""
    );
  };

  return (
    <FormControl
      disabled={disabled}
      variant="outlined"
      style={{ width: "100%", paddingTop: 0 }}
    >
      <Select
        {...rest}
        label={label}
        margin={margin}
        value={controller.value}
        onChange={(e) => {
          controller.value = {
            id: e.target.value.id,
            name: e.target.value.name,
          };
          if (onChange) onChange(e.target.value);
        }}
      >
        {validPlaceHolder() && (
          <MenuItem disabled value="">
            {placeHolder}
          </MenuItem>
        )}
        {collection.map((item) => (
          <MenuItem key={item.id} value={item}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
