import { useState, useEffect } from 'react'

const useUnsaveChanges = (action) => {
    const [isDirty, setDirty] = useState(false);

    useEffect(() => {
        window.onbeforeunload = isDirty && (() => action());
        return () => {
            window.onbeforeunload = null;
        }
    }, [isDirty, action])
    return [
        () => setDirty(true),
        () => setDirty(false)
    ];
}

export default useUnsaveChanges
