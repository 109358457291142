import * as React from "react";
import { AnimatePresence, motion } from "framer-motion";

const variants = {
  initial: { opacity: 0, scale: 0 },
  animate: { opacity: 1, scale: 1 },
  exit: { opacity: 0, scale: 0 },
};

export default function Enter(props) {
  return (
    <AnimatePresence>
      <motion.div
        className={props.className}
        initial="initial"
        animate="animate"
        exit="exit"
        variants={variants}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  );
}
