import { useState } from "react";

export default function useController() {
  var insideValidations = [];

  // Valor default do componente
  const [defaultValue, setDefaultValue] = useState(() => null);

  // Informa se o componente é valido
  const [invalid, setInvalid] = useState(() => false);

  // Mensagem de erro personalizada
  const [errorMessage, setErrorMessage] = useState(() => null);

  const setValue = (value) => {
    setDefaultValue(value);
  };

  // Atribui uma mensagem de erro ao componente
  const setError = (message) => {
    setErrorMessage(message);
    setInvalid(message && message.length > 0 ? true : false);
  };

  // Inicia os valores default do componente
  const init = (controller, value, validations) => {
    controller.value =
      value !== null && value !== undefined
        ? value
        : controller.value === null
        ? ""
        : controller.value;

    if (validations) {
      insideValidations = validations;
    }
  };

  const validate = () => {
    if (!insideValidations) return;
    insideValidations.forEach((validator) => {
      setError(validator(defaultValue));
      if (invalid) return;
    });
  };

  return {
    value: defaultValue,
    error: invalid,
    message: errorMessage,

    // Inicia o controller com um valor default
    init: init,

    // Atribui um valor ao componente
    setValue: setValue,

    // Atribui uma mensagem de erro
    setError: setError,

    // Rodar os componentes de validação
    validate: validate,
  };
}
