export default {
  root: {
    fontSize: '12px !important',
    color: "red",
    paddingBottom: '20px',
    
  },
  contained: {
    marginLeft: 0,
    marginRight: 0,
  },
};
