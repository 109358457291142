import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

// My components
import MiniScoreBoardTeam from './MiniScoreBoardTeam';
import Clock from '../../../../../components/clock';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.up(1257)]: {
      flexDirection: 'row',
    },
  },
  scoreBoard: {
    maxWidth: '100%',
    justifyContent: 'center',
    marginRight: 20,
    [theme.breakpoints.up(1257)]: {
      maxWidth: '60%',
    },
  },
  firstTeam: {
    alignSelf: 'center',
  },
  secondTeam: {
    alignSelf: 'center',
  },
  goals: {
    marginBottom: theme.spacing(-2),
  },
  goal: {
    fontSize: 45,
    [theme.breakpoints.up(1350)]: {
      fontSize: 55,
    },
  },
  versus: {
    fontSize: 30,
    [theme.breakpoints.up(1350)]: {
      fontSize: 40,
    },
  },
  laterais: {
    maxWidth: '14%',
  },
  placar: {
    minWidth: 210,
    alignSelf: 'center',
  },
  blue: {
    backgroundColor: 'blue',
    maxWidth: '25% !important',
  },
  timer: {
    fontSize: 30,
    fontWeight: 200,
    color: theme.palette.action.cancel,
    paddingLeft: 7,
  },
  timeEvents: {
    maxWidth: '100%',
    marginTop: 30,
    [theme.breakpoints.up(1257)]: {
      maxWidth: 560,
      marginTop: 0,
    },
  },
  divider: {
    marginTop: 30,
  },
}));

export default function MiniScoreBoard({
  mainContext,
  occurrenceContext,
  timeEvents,
  occurrencePeriodsHook,
}) {
  const classes = useStyles();

  const getGoals = (teamId, enemyTeamId) => {
    let activeTime = occurrenceContext.activeOccurrenceTime;
    let goals = occurrenceContext.getGoalsInfo(
      undefined,
      activeTime?.timeType?.id,
      teamId
    );
    let golsAgainst = occurrenceContext.golsAgainst(
      undefined,
      activeTime?.timeType?.id,
      enemyTeamId
    );

    return goals.length + golsAgainst.length;
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="xl" className={classes.content}>
        <Grid container direction="row" className={classes.scoreBoard}>
          {/* Time mandante */}
          <Grid item className={classes.firstTeam} xs={4}>
            <MiniScoreBoardTeam
              title="time mandante"
              team={mainContext.match.home}
              occurrencePeriodsHook={occurrencePeriodsHook}
            ></MiniScoreBoardTeam>
          </Grid>

          <Grid item className={classes.placar} xs={4}>
            {/* PLACAR */}
            <Grid
              className={classes.goals}
              item
              container
              justify="center"
              alignItems="baseline"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h2" className={classes.goal}>
                  {' '}
                  {getGoals(
                    mainContext.match.home.id,
                    mainContext.match.away.id
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2" className={classes.versus}>
                  X
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h2" className={classes.goal}>
                  {' '}
                  {getGoals(
                    mainContext.match.away.id,
                    mainContext.match.home.id
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container justify="center" className={classes.timer}>
              <Grid item>
                <Clock
                  mainContext={mainContext}
                  occurrenceContext={occurrenceContext}
                ></Clock>
              </Grid>
            </Grid>
          </Grid>
          {/* Time Visitante */}
          <Grid item className={classes.secondTeam} xs={4}>
            <MiniScoreBoardTeam
              title="time visitante"
              team={mainContext.match.away}
              occurrencePeriodsHook={occurrencePeriodsHook}
            ></MiniScoreBoardTeam>
          </Grid>
        </Grid>
        <Grid container className={classes.timeEvents} justify="center">
          {timeEvents}
        </Grid>
      </Container>
      <Divider className={classes.divider}></Divider>
    </div>
  );
}
