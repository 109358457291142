import constants from './constants';

const functions = {
  // Realiza split dos caracteress
  splitSpace: (value) => {
    var word = value.split(' ')[0];
    return word;
  },

  splitSpaceWith7: (value) => {
    var splicedWord = functions.splitSpace(value);
    if (splicedWord.length > 8) {
      return splicedWord.substring(0, 6) + '...';
    }

    return splicedWord;
  },

  splitSpaceWith10: (value) => {
    var splicedWord = functions.splitSpace(value);
    if (splicedWord.length > 11) {
      return splicedWord.substring(0, 11) + '...';
    }

    return splicedWord;
  },

  splitSpaceWith: (value, number) => {
    var splicedWord = functions.splitSpace(value);
    if (splicedWord.length >= number) {
      return splicedWord.substring(0, number - 3) + '...';
    }

    return splicedWord;
  },

  setNicknameWith: (player, number) => {
    if (player.nickName && player.nickName.trim().length > 0) {
      return functions.splitSpaceWith(player.nickName, number);
    }
    return functions.splitSpaceWith(player.name);
  },

  setNicknameWith7: (player) => {
    if (player.nickName && player.nickName.trim().length > 0) {
      return functions.splitSpaceWith(player.nickName, 7);
    }
    return functions.splitSpaceWith7(player.name);
  },

  setNicknameWith10: (player) => {
    if (player.nickName && player.nickName.trim().length > 0) {
      return functions.splitSpaceWith10(player.nickName);
    }
    return functions.splitSpaceWith10(player.name);
  },

  getPosition: (teamModalityId, position) => {
    return constants.positions
      .find((modality) => modality.modalityId === teamModalityId)
      .positions.find((pos) => pos.id === position.id);
  },

  getTechnicalFunction: (technincalFunction) => {
    if (technincalFunction && technincalFunction.id) {
      return constants.technicalFunctions.find(
        (x) => x.id === technincalFunction.id
      );
    }
  },

  getRefereeFunction: (refereeFunction) => {
    if (refereeFunction && refereeFunction.id) {
      return constants.refereesFunctions.find(
        (x) => x.id === refereeFunction.id
      );
    }
  },

  getGameTimeName: (period) => {
    if (period.timeType.id === 3) return period.timeType.name;
    let gt = constants.gameTimeEvents.find((x) => x.id === period.gameTime.id);
    return gt.name;
  },

  formatDate(date) {
    if (date) {
      var dateObj = new Date(date);
      return dateObj
        .toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(/\//g, '/');
    }
    return '';
  },

  getGolOccurrenceType: () => {
    let type = constants.occurrenceTypes.find((x) => x.id === 4);
    return type;
  },

  getAssistenceOccurrenceType: () => {
    let type = constants.occurrenceTypes.find((x) => x.id === 23);
    return type;
  },

  getEndMatchOccurrenceType: () => {
    let type = constants.occurrenceTypes.find((x) => x.id === 3);
    return type;
  },

  getPlayerEnterOccurrence: () => {
    let type = constants.occurrenceTypes.find((x) => x.id === 22);
    return type;
  },

  getOccurrenceType: (id) => {
    let type = constants.occurrenceTypes.find((x) => x.id === id);
    return type;
  },

  getDefaultClockComment: () => {
    return {
      comment: '',
      clockType: constants.cronometer.find((x) => x.id === 1),
      startTime: '00:00',
    };
  },
};

export default functions;
