import * as React from "react";
import { motion } from "framer-motion";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

const MotionDiv = styled(motion.div)``;

const variants = {
    start: { rotate: [0, 0, 10, -10, 0], transition: { duration: 0.5, loop: Infinity, repeatDelay: 0.2 } },
    stop: { rotate: [0, 0, 0, 0, 0], transition: { duration: 0.5, loop: 1, repeatDelay: 0.2 } },
};

export default function Shake(props) {
    return (
        <Grid item>
            <MotionDiv variants={variants} animate={props.values ? 'start' : 'stop'}>
                {props.children}
            </MotionDiv>
        </Grid>
    );
}
