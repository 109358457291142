import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles, Card } from "@material-ui/core";
// My custom components
import FTextInput from "../../../components/inputs/FTextInput";
import FController from "../../../components/inputs/FControllerHook";
import Loading from "../../../components/loading/loading";
import FButton from "../../../components/button/FButton";
import FLink from "../../../components/button/FLink";
import AuthLogo from "../../../components/logos/auth.logo";

// My Serviecs
import Validators from "../../../utils/validators";
import useForgotPasswordHook from "./hooks/forgotPassord.hook";
import Alert from "../../../components/pops/Alert";
import Code from "./components/code";
import NewPassword from "./components/newPassword";
import Enter from "../../../theme/animations/enter.animations";

import backgroundImg from "../../../assets/images/background_image.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    background: `url(${backgroundImg}) no-repeat top center`,
    padding: theme.spacing(2, 20),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2, 0),
      justifyContent: "center",
    },
  },
  box: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(8, 2),
    width: 500,
    maxHeight: 835,
    height: "100%",
    borderRadius: 10,
    [theme.breakpoints.down("md")]: {
      overflow: "auto",
    },
  },
  content: {
    width: 380,
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  grid: {
    maxWidth: 350,
  },
  logo: {
    paddingBottom: theme.spacing(12),
  },
  bottomTop: {
    paddingTop: theme.spacing(3.0),
  },
  itemCenter: {
    alignSelf: "center",
  },
  spacer: {
    paddingBottom: theme.spacing(2),
  },
}));

export default function ForgotPassword() {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [credentials, setCredentials] = useState({});
  const [loading, response] = useForgotPasswordHook(credentials);
  const [emailSended, setEmailSended] = useState(false);
  const [codeValidated, setCodeValidate] = useState(false);
  const [valid, setValid] = useState(false);

  // InputControllers
  const email = FController();
  const valitators = Validators();

  useEffect(() => {
    if (response.data && response.data.status === 200) {
      let result = response.data.result;
      setUser((user) => result);
      setEmailSended(true);
    }
  }, [response]);

  useEffect(() => {
    setValid(codeValidated);
  }, [codeValidated]);

  const handleForgot = async (e) => {
    handleCancel();
    e.preventDefault();
    setCredentials({ login: email.value, valid: validate() });
  };

  const validate = () => {
    if (!email.value) {
      email.setError("informe o email");
      return false;
    }

    return true;
  };

  const handleCancel = () => {
    setEmailSended(false);
    setUser(null);
  };

  return (
    <form noValidate autoComplete="off" className={classes.root}>
      {/* Alerta de Sucesso */}
      {emailSended && (
        <Alert title="Recuperar Senha" message={response.data.message} />
      )}

      <Card className={classes.box}>
        <Box className={classes.body}>
          {emailSended && valid === true && (
            <Enter className={classes.box}>
              <Code
                cancel={handleCancel}
                setValidated={setCodeValidate}
                user={user}
              ></Code>
            </Enter>
          )}

          {valid === true && (
            <Enter className={classes.box}>
              <NewPassword user={user} />
            </Enter>
          )}
          {/* {true && <NewPassword user={user} />} */}

          {!emailSended && (
            <Enter className={classes.box}>
              <Grid className={classes.content} container direction="column">
                <Grid item className={classes.logo}>
                  <AuthLogo />
                </Grid>
                <Grid item className={classes.spacer}>
                  <FTextInput
                    label="E-mail"
                    disabled={loading}
                    controller={email}
                    validations={[valitators.isRequiredEmail]}
                  ></FTextInput>
                </Grid>
                <Grid item xs={12}>
                  <br></br>
                  <FButton fullWidth disabled={loading} onClick={handleForgot}>
                    Recuperar
                  </FButton>
                </Grid>
                <Loading isLoading={loading} />
                <Grid item className={classes.itemCenter}>
                  <div className={classes.bottomTop}>
                    <FLink text="Voltar" to="/"></FLink>
                  </div>
                </Grid>
              </Grid>
            </Enter>
          )}
        </Box>
      </Card>
    </form>
  );
}
