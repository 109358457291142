import React, { useMemo } from 'react';

// My Components
import Scalation from '../escalation/escalation';
import Referees from '../escalation/referees';
import Loading from '../../../components/loading/loading';
import MatchFooter from '../escalation/components/footers/matchFooter';
import ErrorAlert from '../../../components/pops/ErrorAlert';
import SubHeader from '../../../components/headers/subHeader/index';
import ButtonToTop from '../../../components/button/ButtonToTop';
//import Clock from "../occurrences/components/clock";

export default function Escalation({ mainContext, occurrenceContext }) {
  const home = mainContext.match.home;
  const away = mainContext.match.away;

  console.log('who.Escalation.mainContext', mainContext);
  console.log('who.Escalation.mainContext.match.home', home);
  console.log('who.Escalation.mainContext.match.away', away);

  const homeMemo = useMemo(() => {
    return (
      <div>
        {mainContext.step === 0 && (
          <Scalation mainContext={mainContext} team={home}></Scalation>
        )}
      </div>
    );
  }, [mainContext, home]);

  const awayMemo = useMemo(() => {
    return (
      <div>
        {mainContext.step === 1 && (
          <Scalation mainContext={mainContext} team={away}></Scalation>
        )}
      </div>
    );
  }, [mainContext, away]);

  const referresMemo = useMemo(() => {
    return <div>{mainContext.step === 2 && <Referees />}</div>;
  }, [mainContext.step]);

  return (
    <div>
      <SubHeader mainContext={mainContext}></SubHeader>
      {/* ----------------------------------------------------------
            Step 0: Escalação do Time de Mandante e Equipe Técnica
            ----------------------------------------------------------   */}

      {homeMemo}
      {/* ----------------------------------------------------------
            Step 1: Escalação do Time Visitante e Equipe Técnica
            ----------------------------------------------------------   */}
      {awayMemo}
      {/* ----------------------------------------------------------
            Step 2: Escalação do Time Visitante e Equipe Técnica
            ----------------------------------------------------------   */}
      {referresMemo}
      {/* ----------------------------------------------------------
            Footer: navegação
            ----------------------------------------------------------   */}
      <MatchFooter mainContext={mainContext} />

      {mainContext.error && (
        <ErrorAlert open={mainContext.error} onClose={mainContext.setError} />
      )}

      <Loading isLoading={mainContext.isLoading} />

      <ButtonToTop />
    </div>
  );
}
