import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    textDecoration: "none",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      color: theme.palette.action.main,
      fontWeight: "bold",
      fontSize: 13,
    },
    borderBottom: "solid 0.15em #23ad7b",
    margin: 0,
  },
}));

function FLink({ text, to, className, target = "_self" }) {
  const classes = useStyles();
  return (
    <Typography variant="body2">
      <Link rel='noopener noreferrer' target={target} className={clsx(classes.root, className)} to={to}>
        {text}
      </Link>
    </Typography>
  );
}

export default FLink;
