import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import DeleteOutlineTwoToneIcon from '@material-ui/icons/DeleteOutlineTwoTone';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import Badge from '@material-ui/core/Badge';

// My components
import constants from '../../../../../utils/constants';
import functions from '../../../../../utils/functions';
import FButton from "../../../../../components/button/FButton";
import FTimeInput from '../../../../../components/inputs/FTimeInput';
import useRefereeReportHook from '../hooks/referreReport.hook';
import LoadingBlock from '../../../../../components/loading/loadingBlock'
import RefeereReport from '../RefereeReport';
import useUnsaveChanges from '../../../../../components/hooks/useUnsaveChanges.hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 'solid',
        borderColor: theme.palette.divider,
        borderWidth: 1,
        borderRadius: 6,
        paddingTop: 12,
        paddingBottom: 12,
    },
    loadIcon: {
        height: 120,
        minWidth: 80,
        textAlign: "center",
    },
    text: {
    },
    timeContent: {
        justifyContent: 'center',
        [theme.breakpoints.up(1079)]: {
            justifyContent: 'space-between',
        }
    },
    timeName: {
        margin: '10px 0',
        wordBreak: 'break-word',
    },
    buttons: {
        width: 165,
    },
    button: {
        maxWidth: 50,
    },
    occurrenceTime: {
        "& .MuiOutlinedInput-root": {
            height: 25
        },
        "& .MuiInputBase-input": {
            padding: 0,
            fontWeight: 600,
            cursor: "text",
            textAlign: 'center'
        }
    },
    grid: {
        paddingTop: 10,
        width: '100%'
    },
    refereeButton: {
        minWidth: '220px !important',
    },
    row: {
        paddingTop: '8px !important',
    },
    occurrence: {
        maxHeight: '100%',
        cursor: 'pointer',
        borderBottom: 'solid 1px #e0e0e0',
        '& :hover': {
            backgroundColor: '#f3f3f3',
        },
    },
    occurrenceRow: {
        maxHeight: '100%',
        paddingTop: 4,
        paddingBottom: 4,
    },
    activeOccurrence: {
        backgroundColor: '#e0e0e0',
    },
    playerName: {
        display: 'none',
        alignItems: 'center',
        maxWidth: 85,
        width: '100%',
        [theme.breakpoints.up(1420)]: {
            display: 'flex'
        }
    },
    playerNameResponsive: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 85,
        width: '100%',
        [theme.breakpoints.up(1420)]: {
            display: 'none'
        }
    },
    playerNameFixed: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 85,
        width: '100%',
    },
    playerNumberLeft: {
        paddingLeft: 5,
        textTransform: "lowercase",
        "&:first-letter": {
            textTransform: "capitalize",
        },
    },
    playerNumberRigth: {
        paddingRight: 5,
        textTransform: "lowercase",
        "&:first-letter": {
            textTransform: "capitalize",
        },
    },
    playerOccurrence: {
        maxWidth: 146,
        width: '100%',
        wordWrap: 'break-word',
        "& p": {
            fontWeight: 'bolder',
            [theme.breakpoints.up(1420)]: {
                fontWeight: 'normal'
            }
        }

    },
    timer: {
        flexBasis: 50
    },
    group: {
        maxWidth: 100,
        [theme.breakpoints.up(1000)]: {
            maxWidth: 150,
        },
        [theme.breakpoints.up(1420)]: {
            maxWidth: 240,
        }
    },
    groupLeft: {
        marginRight: 10,
    },
    groupRight: {
        marginLeft: 10
    },
    right: {
        textTransform: "lowercase",
        "&:first-letter": {
            textTransform: "capitalize",
        },
        textAlign: "right"
    },
    left: {
        textTransform: "lowercase",
        "&:first-letter": {
            textTransform: "capitalize",
        },
        textAlign: "left",
    },
    red: {
        backgroundColor: 'red'
    },
    blue: {
        backgroundColor: 'blue'
    }
}));

export default function Occurrences({ occurrenceHook, mainContext }) {

    // Styles
    const classes = useStyles();
    const userefereeHook = useRefereeReportHook();

    const getGameTimeName = () => {
        let period = occurrenceHook.activeOccurrenceTime;
        if (period) {
            if (period.timeType.id === 3) return period.timeType.name;
            let gt = constants.gameTimeEvents.find(x => x.id === period.gameTime.id);
            return `${gt.name} - ${period.timeType.name}`;
        }
        return "";
    }

    const getPlayerData = (player) => {

        if (!player) return undefined;

        if (player.position) return {
            nickName: player.nickName,
            position: player.position,
            number: player.defaultNumber
        }

        if (player.technicalFunction) return {
            nickName: player.nickName,
            technicalFunction: constants.technicalFunctions.find(x => x.id === player.technicalFunction.id)
        }
    }

    const getOccurrenceType = (occurrence) => {
        if (!occurrence.team) return 0;
        if (occurrence.team.id === occurrenceHook.match.home.id) return 1;
        return 2;
    }

    const isActive = (occurrence) => {
        return (occurrenceHook.selectedOccurrence && occurrenceHook.selectedOccurrence.id === occurrence.id)
    }

    const deleteOccurrence = () => {
        occurrenceHook.dispatch({ type: "deleteOccurrence", payload: occurrenceHook.selectedOccurrence });
    }

    const openOccurrenceToEdit = () => {

        if (occurrenceHook.selectedOccurrence.player) {
            occurrenceHook.selectPlayer(
                occurrenceHook.selectedOccurrence.player,
                occurrenceHook.selectedOccurrence.team);
        }

        let parameters = { type: functions.getOccurrenceType(occurrenceHook.selectedOccurrence.occurrenceType.id) };
        parameters.canMultiSelect = false;
        parameters.showSelected = true;

        if ([9, 10].includes(occurrenceHook.selectedOccurrence.occurrenceType.id)) {
            parameters.showReserves = true;
            parameters.showTechnical = true;
        }

        if (occurrenceHook.selectedOccurrence.occurrenceType.id === 21 ||
            occurrenceHook.selectedOccurrence.occurrenceType.id === 22) {
            parameters.showReserves = occurrenceHook.selectedOccurrence.occurrenceType.id === 22;
            parameters.showStarters = occurrenceHook.selectedOccurrence.occurrenceType.id === 21;
            occurrenceHook.setParameters(parameters);
            occurrenceHook.setOpenSingleTeam(true);
            return;
        }


        occurrenceHook.setParameters(parameters);
        occurrenceHook.setOpenSingle(true);

    }

    const select = (occurrence) => {
        occurrenceHook.selectOccurrence(occurrence);
    }

    const save = async () => {
        occurrenceHook.dispatch({ type: 'editChangedOccurrences', payload: occurrenceHook.changedOccurrences });
        setPristine();
        occurrenceHook.clearChangedOccurrences();
    }

    const [setDirty, setPristine] = useUnsaveChanges(() => {
        save();
    });
    const [timerValid, setTimerValid] = useState(false);

    const isTimerValid = (timer) => {

        if (timer.length !== 5 || timer[2] !== ':') {
            return false
        }
        for (let index = 0; index < timer.length; index++) {

            if (index !== 2 && isNaN(timer[index])) {

                return false;
            }

        }
        return true

    }

    const changeValue = (occurrence, value) => {
        occurrence.occurrenceTime = value??"00:00";
        occurrenceHook.addToChangeOccurrences(occurrence);
        setDirty();
    }

    const isChanged = (occurrence) => {
        let ischanged = occurrenceHook.changedOccurrences.find(x => x.id === occurrence.id);
        return ischanged === undefined;
    }

    const enableToEdit = () => {
        return (occurrenceHook.selectedOccurrence
            && ![1, 2, 3, 20].includes(occurrenceHook.selectedOccurrence.occurrenceType.id));
    }
 
    return (
        <div style={{ width: '100%' }}>

            {userefereeHook.showReport && <RefeereReport refereeReportHook={userefereeHook} mainContext={mainContext}></RefeereReport>}
            <Grid
                container
                item
                direction="column"
                className={classes.root}
                spacing={2}>
                <Grid item container className={classes.timeContent} direction='row' alignItems='center'>
                    <Grid item className={classes.timeName}>
                        <Typography variant="h3">
                            {getGameTimeName()}
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.buttons} justify='flex-end'>
                        <Grid container item className={classes.button} justify="flex-end" >
                            <Tooltip title="Salva as alterações realizadas">
                                <div>
                                    <FButton
                                        disabled={occurrenceHook.changedOccurrences.length === 0 || mainContext.isMatchDone || timerValid == false}
                                        thin
                                        icon
                                        type="primary"
                                        onClick={timerValid == true ? save : null}>
                                        <SaveOutlinedIcon></SaveOutlinedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid container item className={classes.button} justify="flex-end" >
                            <Tooltip title="Exclui uma ocorrência selecionada">
                                <div>
                                    <FButton
                                        disabled={!occurrenceHook.selectedOccurrence || mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="main"
                                        onClick={deleteOccurrence}>
                                        <DeleteOutlineTwoToneIcon></DeleteOutlineTwoToneIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid container item className={classes.button} justify="flex-end" >
                            <Tooltip title="Edita uma ocorrência selecionada">
                                <div>
                                    <FButton
                                        disabled={!enableToEdit() || mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="main"
                                        onClick={openOccurrenceToEdit}>
                                        <EditOutlinedIcon></EditOutlinedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item container justify="center">
                    <LoadingBlock loading={occurrenceHook.isLoadingOccurrences}>

                        {occurrenceHook.currentOccurrences.length === 0 &&
                            <Grid item>
                                <Grid item container direction="column"
                                    className={classes.loadIcon}
                                    justify="center"
                                    alignItems="center">
                                    <Typography variant="body2">Não há Occorrências nesse tempo</Typography>
                                </Grid>
                            </Grid>
                        }

                        {occurrenceHook.currentOccurrences.length > 0 &&
                            <div className={classes.grid}>
                                {occurrenceHook.currentOccurrences.map((occurrence) => {

                                    let type = getOccurrenceType(occurrence);
                                    let player = getPlayerData(occurrence.player);

                                    return (
                                        <Box
                                            key={occurrence.id}
                                            onClick={() => select(occurrence)}
                                            className={clsx(classes.occurrence, { [classes.activeOccurrence]: isActive(occurrence) })}
                                        >
                                            <Grid
                                                className={classes.occurrenceRow}
                                                container
                                                justify="center"
                                                alignItems="center"
                                            >
                                                <Grid justify="flex-end" container item className={clsx(classes.group, classes.groupLeft)}>
                                                    <Grid container item justify='flex-end' className={classes.playerName}>
                                                        {(type === 1 && player) &&
                                                            <Typography variant="body2" className={classes.right}>
                                                                {functions.setNicknameWith(occurrence.player, 8)}
                                                            </Typography>
                                                        }
                                                        {(type === 1 && player && player.number) &&
                                                            <Typography className={classes.playerNumberLeft}>
                                                                #{player.number}
                                                            </Typography>
                                                        }
                                                    </Grid>

                                                    <Grid item className={classes.playerOccurrence}>
                                                        {(type === 1 || type === 0) &&
                                                            <Typography className={clsx(classes.right, classes.groupRight)}>
                                                                {occurrence.occurrenceType.name}
                                                            </Typography>
                                                        }
                                                    </Grid>

                                                    <Grid container item justify='flex-end' className={classes.playerNameResponsive}>
                                                        {(type === 1 && player) &&
                                                            <Typography variant="body2" className={classes.right}>
                                                                {functions.setNicknameWith(occurrence.player, 8)}
                                                            </Typography>
                                                        }
                                                        {(type === 1 && player && player.number) &&
                                                            <Typography className={classes.playerNumberLeft}>
                                                                #{player.number}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>

                                                <Grid item className={classes.timer}>
                                                    <Typography variant="h4" className={classes.text}>
                                                        <Badge color={timerValid ==false?"error": "secondary"} variant="dot" invisible={isChanged(occurrence)}>
                                                            <FTimeInput
                                                                mainContext={mainContext}
                                                                className={classes.occurrenceTime}
                                                                value={occurrence.occurrenceTime}
                                                                inputProps={{}}
                                                                onChange={(value) => {
                                                                    const valid = isTimerValid(value)
                                                                    setTimerValid(valid)
                                                                    changeValue(occurrence, value);
                                                                }}
                                                            ></FTimeInput>
                                                        </Badge>
                                                    </Typography>
                                                </Grid>

                                                <Grid justify="flex-start" container item className={clsx(classes.group, classes.groupRight)}>
                                                    <Grid item className={classes.playerOccurrence}>
                                                        {(type === 2 || type === 0) &&
                                                            <Typography className={classes.left}>
                                                                {occurrence.occurrenceType.name}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                    <Grid container item justify='flex-start' className={classes.playerNameFixed}>
                                                        {(type === 2 && player && player.number) &&
                                                            <Typography className={classes.playerNumberRigth}>
                                                                #{player.number}
                                                            </Typography>
                                                        }
                                                        {(type === 2 && player) &&
                                                            <Typography variant="body2" className={classes.left}>
                                                                {functions.setNicknameWith(occurrence.player, 8)}
                                                            </Typography>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                })}
                            </div>
                        }

                    </LoadingBlock>
                </Grid>

                <Grid item container justify="flex-end" >
                    <FButton type="main" className={classes.refereeButton} onClick={() => userefereeHook.setShowReport(true)}>
                        Relatório do árbitro
                    </FButton>
                </Grid>
            </Grid >
        </div>
    )
}
