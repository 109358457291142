import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Images
import logo from "../../assets/images/logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(3),
    position: 'relative',
  },
  image: {
    width: 130,
    [theme.breakpoints.down("md")]: {
      width: 110,
    },
  },
}));

export default function AuthLogo() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <img src={logo} alt="" className={classes.image}></img>
    </div>
  );
}
