import { useState, useContext, useCallback } from "react";
import { MatchContext } from "../../../../../components/contexts/match.provider";

export default function usePlayerHook(team) {
  const mainContext = useContext(MatchContext);
  // Lista de Titulares
  const [players, setScalaedPlayers] = useState(() => team.players);
  const [applyFilter, setApplyFilter] = useState(true);

  const updatePlayer = async (player) => {
    setScalaedPlayers(
      team.players.map((item) => (item.id === player.id ? player : item))
    );
    mainContext.updatePlayer(player);
    updateMatch();
  };

  const updateMatch = () => {
    mainContext.updateMatch();
  };

  const onlyAcceptsNumber = (value) => {
    const re = /^[0-9\b]+$/;
    if (re.test(value)) {
      return parseInt(value);
    } else {
      return null;
    }
  };

  const validateGoalKeeper = useCallback(
    (player) => {
      let totalGoalKeepers = players.filter(
        (x) => x.position.id === 1 && x.isStarter === true
      ).length;

      if (totalGoalKeepers > 1) {
        let goalKeepers = players.filter(
          (x) => x.position.id === 1 && x.isStarter === true
        );
        goalKeepers.forEach((gp) => {
          gp.valid = false;
        });
      } else {
        let array = players.filter(
          (x) => x.position.id === 1 && x.isStarter === true
        );

        array.forEach((gp) => {
          gp.valid = undefined;
        });

        player.valid = undefined;
      }
    },
    [players]
  );

  // Valida número de camisa repetida
  const validatePlayerNumber = useCallback(() => {
    let playersToApplyRole = players.filter(
      (x) =>
        x.isStarter === true ||
        (x.isReserve === true &&
          x.defaultNumber !== "" &&
          x.defaultNumber !== undefined)
    );

    playersToApplyRole.forEach((p) => {
      let obj = playersToApplyRole.find(
        (x) =>
          x.defaultNumber === p.defaultNumber &&
          x.id !== p.id &&
          x.defaultNumber !== "" &&
          x.defaultNumber !== undefined
      );

      if (obj) {
        p.validNumber = false;
      } else {
        p.validNumber = undefined;
      }
    });
  }, [players]);

  const sortGoleiroAndNumber = (a, b) => {
    if (a.position.id === 1) return -1;
    if (b.position.id === 1) return 1;
    if (a.defaultNumber < b.defaultNumber) {
      return -1;
    }
    if (a.defaultNumber > b.defaultNumber) {
      return 1;
    }
    // a deve ser igual a b
    return 0;
  };

  return {
    //Jogadores
    players: players,
    updateMatch: updateMatch,
    updatePlayer: updatePlayer,
    order: mainContext.order,
    orderBy: mainContext.orderBy,
    applyFilter: applyFilter,
    setApplyFilter: setApplyFilter,
    // ----------------------------------------------------------
    // Ordenação de goleiro
    // ----------------------------------------------------------
    comparar: (a, b) => {
      return sortGoleiroAndNumber(a, b);
    },

    // Atribui o jogador ao cargo de capitão
    setCaptain: (player) => {
      var currentCaptain = players.filter((x) => x.isCaptain === true);

      currentCaptain.forEach(p => {
        p.isCaptain = false;
        updatePlayer(p);
      });

      player.isCaptain = true;
      updatePlayer(player);
    },

    // ----------------------------------------------------------
    // Muda o número da camisa do jogador
    // ----------------------------------------------------------
    changeNumber: (value, player) => {
      let result = onlyAcceptsNumber(value);
      player.defaultNumber = result;
      validatePlayerNumber();
      updatePlayer(player);
    },

    // ----------------------------------------------------------
    // Muda a posição do Jogador
    // ----------------------------------------------------------
    changePosition: (value, player) => {
      player.valid = undefined;
      player.position = value;
      validateGoalKeeper(player);
      updatePlayer(player);
    },

    // ----------------------------------------------------------
    // Escala o Jogador como Substituto
    // ----------------------------------------------------------
    setStarter: (player, value) => {
      player.isStarter = value;
      player.isReserve = !value;

      player.isStarterOnTime = value;
      player.isReserveOnTime = !value;

      if (!player.defaultNumber) player.defaultNumber = null;

      validateGoalKeeper(player);
      validatePlayerNumber();
      updatePlayer(player);
    },

    // ----------------------------------------------------------
    // Remove o jogador da escalação.
    // ----------------------------------------------------------
    setUnscaled: (player, value) => {
      if (value === true) return;
      player.isStarter = false;
      player.isReserve = false;

      player.isStarterOnTime = undefined;
      player.isReserveOnTime = undefined;

      if (value === false) {
        validateGoalKeeper(player);
        validatePlayerNumber();
        player.isCaptain = false;
        player.valid = undefined;
        player.validNumber = undefined;
      }

      updatePlayer(player);
    },

    // ----------------------------------------------------------
    // Informa se não está escalado
    // ----------------------------------------------------------
    isUnscaled: (player) => {
      return player.isStarter === false && player.isReserve === false;
    },

    // ----------------------------------------------------------
    // SetOrder
    // ----------------------------------------------------------
    setOrder: (order) => {
      mainContext.setOrder(order ? "desc" : "asc");
    },

    // ----------------------------------------------------------
    // SetOrderBy
    // ----------------------------------------------------------
    setOrderBy: (orderBy) => {
      mainContext.setOrderBy(orderBy);
    },
  };


}
