import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';


// My Components
import FButton from "../../../components/button/FButton";
import LoadingBox from "../../loading/loadingBlock";
import useReportHook from "./hooks/report.hook";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
        position: 'relative',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        maxWidth: 650,
        minWidth: 650,
    },
    body: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    title: {
        fontSize: 25,
        paddingBottom: theme.spacing(1),
    },
}));

function PopReport({ open, handleCancel, handleOk, matchId }) {

    const [value, setValue] = React.useState("0");

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const useRH = new useReportHook();

    const exportReport = async () => {
        await useRH.generate(value, matchId);
        handleOk();
    };

    // Styles
    const classes = useStyles();
    return (
        <div>
            <Dialog
                classes={{ paper: classes.dialogPaper }}
                open={open}
                onClose={handleCancel}
                maxWidth="sm"
                disableBackdropClick={true}
            >
                <Grid
                    container
                    className={classes.root}
                    justify="flex-start"
                    direction="column"
                >
                    {/* Header */}
                    <Grid item xs={12}>
                        <Typography variant="h4" className={classes.title}>
                            Gerar Súmula
                        </Typography>
                        <Typography variant="h4">
                            Escolha abaixo o formato desejado
                        </Typography>
                    </Grid>

                    {/* Body */}
                    <Grid container direction="row" justify='center' className={classes.body}>

                        <LoadingBox loading={useRH.loading}>
                            <Grid item container justify='flex-start'>
                                <RadioGroup aria-label="report" name="report" value={value} onChange={handleChange}>
                                    <FormControlLabel value="1" control={<Radio />} label="Súmula Pré-jogo vazia" />
                                    <FormControlLabel value="2" control={<Radio />} label="Súmula Pré-jogo" />
                                    <FormControlLabel value="3" control={<Radio />} label="Súmula Pós-jogo" />
                                    <FormControlLabel value="4" control={<Radio />} label="Relatório do Árbitro" />
                                </RadioGroup>
                            </Grid>
                        </LoadingBox>
                    </Grid>
                    <DialogActions className={classes.actions}>
                        <FButton thin={true}
                            onClick={handleCancel}
                            className={classes.buttons}
                            type="cancel">
                            Cancelar
                        </FButton>
                        <div className={classes.spacer}></div>
                        <FButton
                            disabled={value === "0"}
                            thin={true}
                            className={classes.buttons}
                            type="main"
                            onClick={exportReport}
                        >
                            Confirmar
                        </FButton>
                    </DialogActions>
                </Grid>
            </Dialog>
        </div >
    )
}

export default PopReport
