import React, { useCallback, useEffect } from 'react'

import { makeStyles } from '@material-ui/core'
import FButton from './FButton'
import ArrowIcon from '@material-ui/icons/ExpandLess'

import Fade from '../../theme/animations/fade.animations'

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '50%',
    position: 'fixed',
    right: 20,
    bottom: 10,
    [theme.breakpoints.down('md')]: {
      right: 20,
      bottom: 10,
    },
    height: '35px !important',
    minWidth: '35px !important',
    width: '35px !important',
  },
  icon: {
    fontSize: 32,
  }
}))

export default function ButtonToTop() {
  const classes = useStyles()
  const rootElement = document.documentElement;

  const [visible, setVisible] = React.useState(false);

  const handleScroll = useCallback(() => {
    var scrollTotal = rootElement.scrollHeight - rootElement.clientHeight
    const totalScrolled = (rootElement.scrollTop / scrollTotal) > 0.80;
    setVisible(prev => totalScrolled);
  }, [rootElement.scrollHeight, rootElement.clientHeight, rootElement.scrollTop]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll])


  return (

    <div>
      {visible &&
        <Fade>
          <FButton className={classes.root} type='disabled' onClick={scrollToTop} >
            <ArrowIcon className={classes.icon} />
          </FButton>
        </Fade>
      }
    </div>

  )
}