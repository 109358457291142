import React, { useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";

import functions from "../../../../../utils/functions";

const useStyles = makeStyles((theme) => ({
    boxPlayer: {
        height: 100,
        width: 71,
        display: "flex",
        position: "relative",
        borderRadius: 5,
        backgroundColor: "white",
        borderColor: theme.palette.text.light,
        "& input, p, hr, h4": {
            color: theme.palette.text.primary,
        },
        cursor: "pointer",
    },
    boxPlayerDeactive: {
        height: 100,
        width: 71,
        position: "relative",
        borderRadius: 5,
        backgroundColor: "white",
        borderColor: theme.palette.text.light,
        "& input, p, hr, h4": {
            color: theme.palette.text.light,
        },
        cursor: "auto",
    },
    boxPlayerActive: {
        borderColor: theme.palette.text.primary,
        borderWidth: 2,
        backgroundColor: '#fdfdfd',
        "& $divider": {
            width: 66,
        },
        "& $cards": {
            width: 67,
            height: 20
        },
    },
    divider: {
        background: theme.palette.divider,
        width: 69,
    },
    nickNameTop: {
        paddingTop: 15,
        maxWidth: '70%',
        overflow: 'hidden'
    },
    nickname: {
        fontWeight: 600,
        fontSize: 12,
        textAlign: 'center',
        textTransform: "lowercase",
        "&:first-letter": {
            textTransform: "capitalize",
        },
    },
    cards: {
        width: 70,
        height: 20,
    },
    position: {
        textAlign: 'center',
        maxHeight: 20
    },
    function: {
        fontSize: 11,
        marginBottom: 5,
        lineHeight: 1.4,
        textAlign: 'center',
        maxHeight: 20,
    },
    yellow: {
        backgroundColor: '#edd20a',
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4
    },
    red: {
        backgroundColor: theme.palette.error.main,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        "& input, p, hr, h4": {
            color: theme.palette.common.white
        },
    },
    sigla: {
        width: 60,
        textAlign: "center",
        paddingTop: 8,
        fontSize: 30,
    },
}));

export default function TechnicalPopCard({ occurrenceHook, team, player, selected, setSelected }) {
    const classes = useStyles();

    const technicalFunction = functions.getTechnicalFunction(
        player.technicalFunction
    );

    const cannotBeSelected = () => {
        return player.hasRedCard || player.enableToSubstituition === 0
    }

    const isSelected = () => {
        return occurrenceHook.selectedPlayers.find(x => x.id === player.id)
    }

    const select = () => {
        if (cannotBeSelected()) return;
        if (setSelected) setSelected(player, team);
    }

    // const shortName = (technicalFunction) => {
    //     let palavras = technicalFunction.name.split(' ');
    //     if (palavras.length === 1 && technicalFunction.name.length >= 10) {
    //         return technicalFunction.shortName;
    //     }
    //     return technicalFunction.name;
    // }

    const getYellowCards = () => {
        let total = occurrenceHook.getYellowCards(player.id);
        return total;
    }

    const getRedCards = useCallback(() => {
        let total = occurrenceHook.getRedCards(player.id);
        return total;
    }, [occurrenceHook, player.id]);

    useEffect(() => {
        player.hasRedCard = getRedCards() !== undefined;
    }, [player, getRedCards])

    return (
        <Box 
            border={1}
            className={clsx(classes.boxPlayer, {
                [classes.boxPlayerActive]: isSelected(),
                [classes.boxPlayerDeactive]: cannotBeSelected()
            })}
            onClick={select}
        >
            <Grid
                container
                direction="column"
                justify="space-between"
                alignItems="center"
                spacing={0}
            >
                <Grid item className={classes.nickNameTop}>
                    <Tooltip title={player.name}>
                        <Typography variant="h4" className={classes.nickname}>
                            {functions.setNicknameWith(player, 8)}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item >
                    <Divider className={classes.divider} />
                </Grid>
                <Grid item >
                    <Tooltip title={technicalFunction.name}>
                        <Typography variant="body2" className={classes.function}>
                            {technicalFunction.shortName}
                        </Typography>
                    </Tooltip>
                </Grid>
                <Grid item
                    className={clsx(classes.cards, { [classes.yellow]: getYellowCards(), [classes.red]: getRedCards() })}>
                    &nbsp;
                </Grid>
            </Grid>
        </Box >
    );
}
