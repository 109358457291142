import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import FTimeInput from '../../../../../components/inputs/FTimeInput';

const useStyles = makeStyles((theme) => ({
  bigBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    border: `1px solid #cccccc`,
    borderRadius: 5,
    backgroundColor: '#fbfbfb',
    textAlign: 'center',
    width: '100%',
    height: 220,
  },
  grid: {
    padding: 2,
  },
  timer: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'white',
    },
    '& *': {
      align: 'center',
      textAlign: 'center',
    },
    marginTop: 40,
  },
  internal: {},
}));

export default function PopBigBox(props) {
  // Styles
  const classes = useStyles();
  const isTimerValid = (timer) => {
    if (timer.length !== 5 || timer[2] !== ':') {
      return false;
    }
    for (let index = 0; index < timer.length; index++) {
      if (index !== 2 && isNaN(timer[index])) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className={classes.bigBox}>
      <Grid container direciton="column" justify="center" spacing={2}>
        {props.children ? (
          <Grid
            container
            item
            justify="center"
            alignItems="flex-start"
            className={classes.internal}
          >
            {props.children}
          </Grid>
        ) : null}

        <Grid
          container
          item
          justify="center"
          className={classes.timer}
          alignItems="center"
          xl={3}
          margin
        >
          <FTimeInput
            value={props.timeValue}
            inputProps={{}}
            onChange={(value) => {
              const valid = isTimerValid(value);
              props.handleTimerValid(valid);
              props.setTimeValue(value);
            }}
          ></FTimeInput>
        </Grid>
      </Grid>
    </div>
  );
}
