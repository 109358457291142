import React, { useState, useContext } from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// My custom components
import FController from "../../../../../components/inputs/FControllerHook";
import constants from "../../../../../utils/constants";
import FSelect from "../../../../../components/inputs/FSelect";
import FTimeInput from "../../../../../components/inputs/FTimeInput";
import useCronometerHook from "../hooks/cronometer.hook";
import { MatchContext } from '../../../../../components/contexts/match.provider'

export default function Cronometer({ clockComment }) {
    const cronometerHook = useCronometerHook(clockComment);
    const [myValue, setMyValue] = useState(clockComment.startTime);
    const mainContext = useContext(MatchContext)

    const changeInitValue = (value) => {
        setMyValue(value);
        var validMinute = /^([0-5]?[0-9]|5[0-9]):([0-5][0-9])(:[0-5][0-9])?$/.test(value);

        if (validMinute) {
            cronometerHook.setInit(value);
        }

    }

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <Typography variant="h6">Cronômetro</Typography>
            </Grid>
            <Grid item>
                <FSelect
                    disabled={mainContext.isMatchDone}
                    value={cronometerHook.clockType}
                    controller={FController()}
                    margin="none"
                    collection={constants.cronometer}
                    onChange={(value) => {
                        cronometerHook.setClockType(value);
                    }}
                ></FSelect>
            </Grid>

            {cronometerHook.clockType && cronometerHook.clockType.id === 2 && (
                <Grid item>
                    <FTimeInput
                        value={myValue}
                        onChange={(value) => {
                            changeInitValue(value);
                        }}
                    ></FTimeInput>
                </Grid>
            )}

            <Grid item>
                <Typography>
                    {cronometerHook.clockType &&
                        constants.cronometer[cronometerHook.clockType.id - 1].message}
                </Typography>
            </Grid>
        </Grid>
    );
}
