import { useState, useEffect } from "react";
import { api } from "../../../../../../services/api.services";
export default function useSearchPlayerToAdd(criteria) {
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function searchPlayers() {
      setLoading(true);
      setResponse([]);
      try {
        var resp = await api.get("player/searchplayer", { params: criteria });
        setResponse([]);
        setResponse(resp.data);
      } catch (error) {
        setResponse(error);
      } finally {
        setLoading(false);
      }
    }
    if (criteria.Sentence && criteria.Sentence.length > 2) {
      searchPlayers(criteria.search);
    } else {
      setResponse([]);
    }
  }, [criteria]);

  return [loading, response];
}
