export default {
    root: {
        borderRadius: 5,
    },
    text: {
        padding: "10px 8px",
    },
    label: {
        textTransform: "none",
    },
};
