import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import HourglassEmptyRoundedIcon from '@material-ui/icons/HourglassEmptyRounded';
import CompareArrowsRoundedIcon from '@material-ui/icons/CompareArrowsRounded';
import Tooltip from "@material-ui/core/Tooltip";


// My components
import PopTeamBody from '../popTeamBody';
import FButton from "../../../../../components/button/FButton";
import Occurrences from "../occurrences";
import Confirm from "../../../../../components/pops/Confirm";
import functions from "../../../../../utils/functions";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
    players: {
        paddingTop: 15
    },
    playersColumn: {
        maxWidth: '29%',
    },
    buttom: {
        maxWidth: 50,
        "& span": {
            fontSize: 22,
        }
    },
    title: {
        display: 'flex',
        width: '46%',
    },
    invert: { transform: 'rotate(180deg) !important' },
    red: {
        // backgroundColor: "red"
    },
    titleResponsive: {
        display: 'none',
        width: '46%',

        [theme.breakpoints.up(1348)]: {
            display: 'flex'
        }
    },
    titleResponsiveInvisible: {
        display: 'flex',
        width: '46%',

        [theme.breakpoints.up(1348)]: {
            display: 'none',
        }
    }
}));


export default function Body({ occurrenceHook, mainContext }) {
    // Styles
    const classes = useStyles();
    const [confirmEnter, setConfirmEnter] = useState();

    const hasSelectedPlayer = (team) => {
        if (!occurrenceHook.teamSelected) return false;
        let hasSelectedPlayer = occurrenceHook.selectedPlayers[0];
        return hasSelectedPlayer && !hasSelectedPlayer.technicalFunction && occurrenceHook?.teamSelected.id === team.id;
    }

    const hasSelectedCaptain = (team) => {
        if (!occurrenceHook.teamSelected) return false;
        let hasSelectedPlayer = occurrenceHook.selectedPlayers[0];
        return hasSelectedPlayer && hasSelectedPlayer.isCaptain && !hasSelectedPlayer.technicalFunction && occurrenceHook?.teamSelected.id === team.id
    }

    const requireTime = (team) => {
        occurrenceHook.dispatch({ type: 'requireTime', payload: team })
    }

    const openConfirm = (team) => {
        occurrenceHook.setTeamSelected(team);
        let players = team.players.filter(x => x.isStarterOnTime === true);
        occurrenceHook.setSelectedPlayers(prev => [...players]);

        setConfirmEnter(true);
    }

    const handleEnterOk = () => {
        let occurrence = { occurrenceType: functions.getPlayerEnterOccurrence() }
        occurrenceHook.dispatch({ type: 'addOccurrence', payload: occurrence });
        handleEnterCancel();
    }

    const handleEnterCancel = () => {
        setConfirmEnter(false);
        occurrenceHook.clearSelectedPlayer();
    }

    return (
        <div className={classes.root}>

            {confirmEnter && (
                <Confirm
                    open={confirmEnter}
                    title="Registrar entrada"
                    handleOk={handleEnterOk}
                    handleAbort={handleEnterCancel}
                    message="Deseja registrar a entrada dos jogadores escalados? Caso a entrada já tenha sido registrada, pode ocorrer a duplicidade de registros."
                />
            )}
            <Grid
                container
                justify="center"
                spacing={4}
            >
                <Grid container item direction="column" className={classes.playersColumn}>
                    <Grid container item spacing={2} justify="flex-end"  >
                        <Grid className={classes.buttom} container item justify="flex-end" >
                            <Tooltip title="Registra a entrada em quadra/campo">
                                <div>
                                    <FButton
                                        disabled={mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="cancel"
                                        onClick={() => openConfirm(occurrenceHook.match.home)} >
                                        <ExitToAppOutlinedIcon></ExitToAppOutlinedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.buttom} container item justify="flex-end" >
                            <Tooltip title="Pedido de Tempo">
                                <div>
                                    <FButton disabled={mainContext.isMatchDone} thin icon type="cancel" onClick={() => requireTime(occurrenceHook.match.home)} >
                                        <HourglassEmptyRoundedIcon></HourglassEmptyRoundedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.buttom} container item justify="flex-end" >
                            <Tooltip title="Mudar Capitão">
                                <div>
                                    <FButton 
                                        disabled={!hasSelectedCaptain(occurrenceHook.match.home) || mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="main"
                                        onClick={occurrenceHook.initCaptainSubstituition}
                                    >
                                        C
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.buttom} container item justify="flex-end" >
                            <Tooltip title="Efetua a Substituição">
                                <div>
                                    <FButton
                                        disabled={!hasSelectedPlayer(occurrenceHook.match.home) || mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="main"
                                        onClick={occurrenceHook.initSubstituition}
                                    >
                                        <CompareArrowsRoundedIcon></CompareArrowsRoundedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.title} container item alignItems="center" justify="flex-end">
                            <Typography variant="h4" >
                                Titulares
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container justify="center" className={classes.players}>

                        <PopTeamBody
                            justify="flex-end"
                            showStarterTitle={false}
                            showTeamName={false}
                            team={occurrenceHook.match.home}
                            showReserves={true}
                            showTechnical={true}
                            setPlayer={occurrenceHook.selectSinglePlayer}
                        >
                        </PopTeamBody>

                    </Grid>
                </Grid>

                {/* Grid de Occorrencias ou relatório do Arbrito */}

                <Grid container xs={5} item direction="column">
                    <Occurrences occurrenceHook={occurrenceHook} mainContext={mainContext}></Occurrences>
                </Grid>

                {/* Grid de Occorrencias ou relatório do Arbrito */}

                <Grid container item direction="column" className={classes.playersColumn} >
                    <Grid container item spacing={2} justify="flex-start"  >
                        <Grid className={classes.titleResponsive} container item alignItems="center" justify="flex-start">
                            <Typography variant="h4" >
                                Titulares
                            </Typography>
                        </Grid>
                        <Grid className={classes.buttom} container item justify="flex-start" >
                            <Tooltip title="Efetua a Substituição">
                                <div>
                                    <FButton
                                        disabled={!hasSelectedPlayer(occurrenceHook.match.away) || mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="main"
                                        onClick={occurrenceHook.initSubstituition}
                                    >
                                        <CompareArrowsRoundedIcon></CompareArrowsRoundedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.buttom} container item justify="flex-start" >
                            <Tooltip title="Mudar Capitão">
                                <div>
                                    <FButton 
                                        disabled={!hasSelectedCaptain(occurrenceHook.match.away) || mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="main"
                                        onClick={occurrenceHook.initCaptainSubstituition}
                                    >
                                        C
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.buttom} container item justify="flex-start" >
                            <Tooltip title="Pedido de Tempo">
                                <div>
                                    <FButton
                                        disabled={mainContext.isMatchDone}
                                        thin
                                        icon
                                        type="cancel"
                                        onClick={() => requireTime(occurrenceHook.match.away)}
                                    >
                                        <HourglassEmptyRoundedIcon></HourglassEmptyRoundedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.buttom} container item justify="flex-start" >
                            <Tooltip title="Registra a entrada em quadra/campo">
                                <div>
                                    <FButton
                                        disabled={mainContext.isMatchDone}
                                        className={classes.invert}
                                        thin
                                        icon
                                        type="cancel"
                                        onClick={() => openConfirm(occurrenceHook.match.away)}
                                    >
                                        <ExitToAppOutlinedIcon ></ExitToAppOutlinedIcon>
                                    </FButton>
                                </div>
                            </Tooltip>
                        </Grid>
                        <Grid className={classes.titleResponsiveInvisible} container item alignItems="center" justify="flex-start">
                            <Typography variant="h4" >
                                Titulares
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.players}>

                        <PopTeamBody
                            justify="flex-start"
                            showStarterTitle={false}
                            showTeamName={false}
                            team={occurrenceHook.match.away}
                            showReserves={true}
                            showTechnical={true}
                            setPlayer={occurrenceHook.selectSinglePlayer}
                        >
                        </PopTeamBody>

                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
