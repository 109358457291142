import React, { useState } from 'react';

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Divider,
  Typography,
} from '@material-ui/core';

import Skeleton from '@material-ui/lab/Skeleton';
import FButton from './FButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ApprovedIcon from '@material-ui/icons/AssignmentTurnedIn';
import ReprovedIcon from '@material-ui/icons/AssignmentLate';
import Carousel from 'react-material-ui-carousel';
import PlayerServices from '../../services/player.services';

const useStyles = makeStyles((theme) => ({
  IconDocWrapper: {
    cursor: 'pointer',
  },
  arrowButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'solid 1px',
    height: 40,
    width: 40,
    borderRadius: 5,
    backgroundColor: '#f3f3f3',
    borderColor: theme.palette.action.disabled,
    textAlign: 'center',
    cursor: 'pointer',
    '& svg': {
      fontWeight: 600,
      fontSize: 26,
      color: theme.palette.action.cancel,
    },
  },
}));

export default function ButtonDocument({
  documentId,
  isVeryfiDoc,
  handleAction,
}) {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [currentIndex, setcurrentIndex] = useState(0);
  const [playerData, setPlayerData] = useState([]);

  const getPlayerDocsById = async () => {
    try {
      const playerDocs = await PlayerServices().playerDocs(documentId);
      setPlayerData(playerDocs);
    } catch (error) {
      console.log('getPlayerDocsById', error);
    } finally {
      setIsloading(false);
    }
  };

  const getDocuments = async () => {
    setIsVisible(true);
    getPlayerDocsById();
  };

  const approveDocument = async () => {
    setIsloading(true);
    try {
      PlayerServices().playerDocsApprove(documentId);
      handleAction('OK');
    } catch (error) {
      console.log('approveDocument', error);
    } finally {
      setIsloading(false);
      setIsVisible(false);
    }
  };

  const reproveDocument = async () => {
    setIsloading(true);

    try {
      PlayerServices().playerDocsReject(documentId);
      handleAction('Pendente');
    } catch (error) {
      console.log('reproveDocument', error);
    } finally {
      setIsloading(false);
      setIsVisible(false);
    }
  };

  const SkeletonModal = () => {
    return (
      <Box
        style={{
          width: '600px',
        }}
        p={3}
      >
        <Skeleton
          variant="rect"
          width="50%"
          height="20px"
          style={{
            marginBottom: 20,
          }}
        />
        <Skeleton
          variant="rect"
          width="100%"
          height="300px"
          style={{
            margin: '20px 0',
          }}
        />
        <Box
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          width={'100%'}
        >
          <Skeleton
            variant="rect"
            width={60}
            height={40}
            style={{
              marginRight: 10,
            }}
          />
          <Skeleton variant="rect" width={60} height={40} />
        </Box>

        <Box
          display={'flex'}
          flexDirection={'row'}
          width={'100%'}
          justifyContent={'space-between'}
          style={{
            margin: '20px 0',
          }}
        >
          <Skeleton variant="rect" width={180} height={40} />
          <Skeleton variant="rect" width={180} height={40} />
          <Skeleton variant="rect" width={180} height={40} />
        </Box>
      </Box>
    );
  };

  const EmptyList = () => {
    return (
      <Box
        style={{
          width: '500px',
        }}
      >
        <Typography>Não possui documento cadastrado</Typography>
      </Box>
    );
  };

  const emptyList = !isLoading && playerData.length === 0;

  return (
    <>
      <Box className={classes.IconDocWrapper} onClick={getDocuments}>
        {isVeryfiDoc ? (
          <ApprovedIcon color="primary" />
        ) : (
          <ReprovedIcon color="disabled" />
        )}
      </Box>

      <Dialog open={isVisible} keepMounted onClose={() => setIsVisible(false)}>
        {isLoading ? (
          <SkeletonModal />
        ) : (
          <>
            <DialogTitle>Validação de documento</DialogTitle>
            <Divider
              style={{
                margin: '10px 20px 20px 20px',
              }}
            />
            <DialogContent>
              {emptyList ? (
                <EmptyList />
              ) : (
                <>
                  <Carousel
                    index={currentIndex}
                    autoPlay={false}
                    navButtonsAlwaysVisible={false}
                    navButtonsWrapperProps={{
                      style: {
                        display: 'none',
                      },
                    }}
                  >
                    {playerData.map((playerData, index) => (
                      <Box
                        key={index}
                        style={{
                          width: '600px',
                          height: '300px',
                        }}
                      >
                        <img
                          style={{
                            objectFit: 'contain',
                            width: '600px',
                            height: '300px',
                          }}
                          src={playerData.fileURl}
                          alt={playerData.fileURl}
                        />
                      </Box>
                    ))}
                  </Carousel>

                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Button
                      className={classes.arrowButton}
                      style={{
                        marginRight: 10,
                      }}
                      onClick={() =>
                        currentIndex === 0
                          ? {}
                          : setcurrentIndex(currentIndex - 1)
                      }
                    >
                      <ArrowBackIcon />
                    </Button>
                    <Button
                      className={classes.arrowButton}
                      onClick={() =>
                        currentIndex === 1
                          ? setcurrentIndex(0)
                          : setcurrentIndex(currentIndex + 1)
                      }
                    >
                      <ArrowForwardIcon />
                    </Button>
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions
              style={{
                margin: '20px 0',
                padding: '0 24px',
              }}
            >
              {emptyList ? (
                <FButton thin type="cancel" onClick={() => setIsVisible(false)}>
                  Cancelar
                </FButton>
              ) : (
                <>
                  <FButton
                    fullWidth
                    thin
                    type="danger"
                    onClick={() => reproveDocument()}
                  >
                    Reprovar
                  </FButton>

                  <FButton
                    fullWidth
                    thin
                    type="cancel"
                    onClick={() => setIsVisible(false)}
                  >
                    Cancelar
                  </FButton>

                  <FButton
                    fullWidth
                    thin
                    type="primary"
                    onClick={() => approveDocument()}
                  >
                    Aprovar
                  </FButton>
                </>
              )}
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
