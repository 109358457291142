import React, { useMemo, useContext, useEffect, useCallback } from 'react';

import { MatchContext } from '../../components/contexts/match.provider';
import { OccurrenceContext } from '../../components/contexts/occurrences.provider';
import Escalation from './escalation/index';
import Occurrences from './occurrences/index';
import MainHeader from '../../components/headers/mainHeader/index';
import worker_script from '../../utils/worker-timer.js';
import constants from '../../utils/constants';
import useClockHook from '../../components/clock/clock.hook.jsx';
import repository from '../../data/repository';

export default function Match() {
  const mainContext = useContext(MatchContext);
  const occurrenceContext = useContext(OccurrenceContext);
  const timerWorker = useMemo(() => new Worker(worker_script), []);
  const useclockHook = useClockHook(occurrenceContext, false);
  const data = useMemo(() => repository(), []);
  const match = data.getItemParse(constants.MATCH);

  const startWebWorkerTimer = useCallback(() => {
    timerWorker.postMessage({
      play: 'on',
      currentTime: data.getItem(constants.TIMER),
      type: match.clockComment.clockType.id,
    });
    occurrenceContext.setCronometerVisible(true);
  }, [data, match.clockComment.clockType.id, occurrenceContext, timerWorker]);

  const pauseWebWorkerTimer = useCallback(() => {
    timerWorker.postMessage({ play: 'off' });
  }, [timerWorker]);

  useEffect(() => {
    timerWorker.onmessage = ({ data: { timerMinutes } }) => {
      // TODO aqui é o limitador de tempo
      useclockHook.updateValue(timerMinutes);
    };
  }, [timerWorker, useclockHook]);

  useEffect(() => {
    if (occurrenceContext.play) {
      startWebWorkerTimer();
    } else {
      pauseWebWorkerTimer();
    }
  }, [occurrenceContext.play, pauseWebWorkerTimer, startWebWorkerTimer]);

  // ---------------------------------------------------
  // Memos
  // ---------------------------------------------------

  const headerMemo = useMemo(() => {
    return (
      <div>
        <MainHeader
          mainContext={mainContext}
          occurrenceContext={occurrenceContext}
        ></MainHeader>
      </div>
    );
  }, [mainContext, occurrenceContext]);

  const escalationMemo = useMemo(() => {
    return (
      <div>
        {mainContext.index === 0 && (
          <Escalation
            mainContext={mainContext}
            occurrenceContext={occurrenceContext}
          ></Escalation>
        )}
      </div>
    );
  }, [mainContext, occurrenceContext]);

  const occurrencesMemo = useMemo(() => {
    return (
      <div>
        {mainContext.index === 1 && (
          <Occurrences
            occurrenceContext={occurrenceContext}
            mainContext={mainContext}
          ></Occurrences>
        )}
      </div>
    );
  }, [mainContext, occurrenceContext]);

  return (
    <div>
      {headerMemo}

      {/* ----------------------------------------------------------
            Index 0: Escalação 
            ----------------------------------------------------------   */}
      {escalationMemo}

      {/* ----------------------------------------------------------
            Index 1: Occurrences
            ----------------------------------------------------------   */}
      {occurrencesMemo}
    </div>
  );
}
