import axios from "axios";
export const CancelToken = axios.CancelToken;

const apidefault = axios.create({ baseURL: process.env.REACT_APP_API_URL });
const apiApp = axios.create({ baseURL: process.env.REACT_APP_APP_URL });

apidefault.interceptors.request.use(function (config) {
    console.log("who.REACT_APP_API_URL", process.env.REACT_APP_API_URL);
    console.log("who.REACT_APP_APP_URL", process.env.REACT_APP_APP_URL);
    // Faça algo antes que a solicitação seja enviada
    const dataUser = localStorage.getItem('user');
    if (dataUser) {
        let user = JSON.parse(dataUser);
        config.headers['Authorization'] = `Bearer ${user.accessToken}`;
    }
    return config;
}, function (error) {
    // Faça algo com erro da solicitação
    return Promise.reject(error);
});

// Default Interceptors
apidefault.interceptors.response.use(
    async (response) => (
        {
            status: response.status,
            data: response.data,
            valid: response.data.valid,
        }),
    async (error) => {
        // handle error
        return Promise.reject({
            status: error.response.status,
            data: error.response.data,
            valid: false,
        });
    }
);

// Api App Interceptors
apiApp.interceptors.response.use(
    async (response) => ({ status: response.status, data: response.data }),
    async (error) => {
        // handle error
        return Promise.reject({
            status: error.response.status,
            data: error.response.data,
        });
    }
);

export const api = apidefault;
export const app = apiApp;
