import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Card } from '@material-ui/core';
import { useHistory } from 'react-router';

// My custom components
import FTextInput from '../../../components/inputs/FTextInput';
import FController from '../../../components/inputs/FControllerHook';
import FLink from '../../../components/button/FLink';
import { Typography } from '@material-ui/core';

// My Serviecs
import Validators from '../../../utils/validators';
import useLoginHook from './hooks/login.hook';
import Loading from '../../../components/loading/loading';
import FButton from '../../../components/button/FButton';
import AuthLogo from '../../../components/logos/auth.logo';
import Enter from '../../../theme/animations/enter.animations';
import ErrorAlert from '../../../components/pops/ErrorAlert';
import constants from '../../../utils/constants';

import backgroundImg from '../../../assets/images/background_image.png';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: `url(${backgroundImg}) no-repeat top center`,
    padding: theme.spacing(2, 20),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 0),
      justifyContent: 'center',
    },
  },
  box: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(8, 2),
    width: 500,
    maxHeight: 835,
    height: '100%',
    borderRadius: 10,
    [theme.breakpoints.down('md')]: {
      overflow: 'auto',
    },
  },
  content: {
    width: 380,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
    paddingBottom: theme.spacing(6),
    paddingTop: theme.spacing(2),
    fontSize: 30,
    fontFamily: 'Montserrat BlackItalic',
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(0.5),
    },
  },
  center: {
    top: 'auto',
    left: 'auto',
    position: 'absolute',
  },
  logo: {
    alignItems: 'center',
  },
  bottomTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(1),
    },
  },
  spacer: {
    paddingBottom: theme.spacing(3),
  },
  bottomText: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(3),
  },
  linkRoot: {
    textDecoration: 'none',
    color: theme.palette.action.main,
    fontWeight: 'bold',
    fontSize: 13,
    '&:focus, &:hover, &:visited, &:link, &:active': {
      color: theme.palette.action.main,
      fontWeight: 'bold',
      fontSize: 13,
    },
    borderBottom: 'solid 0.15em #23ad7b',
    margin: 0,
  },
}));

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
  const useloginHook = new useLoginHook();
  const [openError, setOpenError] = useState(false);
  const loading = useloginHook.loading;

  // InputControllers
  const email = FController();
  const password = FController();
  const token = FController();
  const valitators = Validators();

  // useEffect(() => {
  //     setOpenError(response.valid === false);
  // }, [response]);

  const handleLogin = async (e) => {
    e.preventDefault();

    let response = await useloginHook.login({
      email: email.value,
      password: password.value,
      token: token.value,
      valid: validate(),
    });

    setOpenError(response.status !== 200);
    if (response.status === 200) {
      history.push('/match');
    }
  };

  const validate = () => {
    if (!email.value) {
      email.setError('informe o email');
      return false;
    }

    if (!password.value) {
      password.setError('informe a senha');
      return false;
    }

    if (!token.value) {
      token.setError('informe o token');
      return false;
    }

    return true;
  };

  const close = () => {
    setOpenError(false);
  };

  return (
    <form noValidate autoComplete="off" className={classes.root}>
      {openError && (
        <ErrorAlert
          open={openError}
          onClose={close}
          message={constants.LOGINERROR}
          response={useloginHook.response}
        ></ErrorAlert>
      )}

      <Card className={classes.box}>
        <Enter>
          <Grid container direction="column" className={classes.content}>
            <Grid item className={classes.logo}>
              <AuthLogo />
            </Grid>
            <Grid item className={classes.title}>
              PORTAL DE <br />
              SÚMULA ONLINE
            </Grid>
            <Grid item className={classes.spacer}>
              <FTextInput
                id="email"
                name="email"
                label="E-mail"
                fullWidth
                disabled={loading}
                controller={email}
                validations={[valitators.isRequiredEmail]}
              ></FTextInput>
            </Grid>
            <Grid item className={classes.spacer}>
              <FTextInput
                id="password"
                name="password"
                type="password"
                label="Senha"
                fullWidth
                controller={password}
                disabled={loading}
                autoComplete="off"
                validations={[valitators.isRequiredPassword]}
              ></FTextInput>
            </Grid>
            <Grid item className={classes.spacer}>
              <FTextInput
                id="token"
                name="token"
                maxlength="8"
                label="Token"
                fullWidth
                controller={token}
                disabled={loading}
                validations={[valitators.isRequiredToken]}
              ></FTextInput>
            </Grid>
            <Grid item className={classes.bottomTop}>
              <FButton
                id="button"
                name="button"
                type="main"
                fullWidth
                disabled={loading}
                onClick={handleLogin}
              >
                Login
              </FButton>
            </Grid>
            <Loading isLoading={loading} />
            <Grid item className={classes.bottomText}>
              <Grid item>
                {/* TODO REMOVER QUANDO AJUSTAR FLUXO DE RECUPERAR SENHA <FLink text="Esqueceu sua senha?" to="/forgotpassword"></FLink> */}
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  <a
                    rel="noopener noreferrer"
                    className={classes.linkRoot}
                    target="_blank"
                    href="http://v1.sumula.futstat.com.br"
                  >
                    Versão anterior da súmula
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Enter>
      </Card>
    </form>
  );
}
