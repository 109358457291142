import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import useOccurrenceHook from '../../../components/hooks/occorrence.hook';

const useStyles = makeStyles((theme) => ({
  box: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
  },
  team: {
    fontSize: 14,
    [theme.breakpoints.up(1350)]: {
      fontSize: 16,
    },
  },
  teamName: {
    fontSize: 20,
    textAlign: 'center',
    marginTop: 2,
    marginBottom: 5,
    [theme.breakpoints.up(1350)]: {
      fontSize: 24,
    },
  },
  colors: {
    fontSize: 12,
    [theme.breakpoints.up(1350)]: {
      fontSize: 14,
    },
  },
  faults: {
    padding: 5,
    fontSize: 14,
    color: theme.palette.action.main,
    [theme.breakpoints.up(1350)]: {
      fontSize: 16,
    },
  },
  right: {
    textAlign: 'right',
  },
}));

export default function ScoreBoardTeamEsquerda({
  title,
  team,
  showFaults = true,
  occurrencePeriodsHook,
}) {
  const classes = useStyles();

  const occurrenceHook = useOccurrenceHook();

  const getFaults = () => {
    let faults = occurrenceHook.getFaults(team.id);
    return `Faltas acumuladas no jogo : ${faults.length}`;
  };

  var faults = getFaults();

  //faltas acumuladas
  const getFaultsByTime = () => {
    let faults = occurrenceHook.activeOccurrenceTime;
    const getLastRegularTime = occurrencePeriodsHook.periods.filter(
      (reg) => reg.timeType.id === 1
    ).length;

    const getAllProrrogationTime = occurrencePeriodsHook.periods.filter(
      (prorrog) => prorrog.timeType.id === 2
    );

    let activeIndexProrrogationTime = getAllProrrogationTime.findIndex(
      (prog) => prog.gameTime.id === faults?.gameTime?.id
    );

    let totalAllProrrogationTimes = getAllProrrogationTime
      .slice(0, activeIndexProrrogationTime)
      .map(
        (prorrogation) =>
          occurrenceHook.getProrrogationFaults(
            team.id,
            prorrogation.gameTime.id
          ).length
      );

    let faultsByTime = occurrenceHook.getFaultsByTime(
      team.id,
      faults?.gameTime?.id,
      faults?.timeType?.id
    );
    const faultsByLastRegularTime = occurrenceHook.getFaultsByTime(
      team.id,
      getLastRegularTime,
      1
    );
    let secondHalfFaults = occurrenceHook.getFaultsByTime(team.id, 2, 1);
    let thirdyHalfFaults = occurrenceHook.getFaultsByTime(team.id, 3, 1);
    let fourthHalfFaults = occurrenceHook.getFaultsByTime(team.id, 4, 1);
    let prorrogationFaults = occurrenceHook.getProrrogationFaults(
      team.id,
      faults?.gameTime?.id
    );
    let prorrogation2 = occurrenceHook.getProrrogationFaults(
      team.id,
      faults?.gameTime?.id - 1
    );
    let prorrogation3 = occurrenceHook.getProrrogationFaults(
      team.id,
      faults?.gameTime?.id - 2
    );
    let prorrogation4 = occurrenceHook.getProrrogationFaults(
      team.id,
      faults?.gameTime?.id - 3
    );

    // return `Faltas acumuladas no tempo : ${faults?.timeType?.id===1 ? faultsByTime.length:secondHalfFaults.length+prorrogationFalts.length}`;
    /*if (faults?.timeType?.id === 3) {
            return `Faltas acumuladas no tempo : ${0}`;
        } if ((faults?.gameTime?.id === 1 || faults?.gameTime?.id === 3 || faults?.gameTime?.id === 4) && faults?.timeType?.id === 1) {
            return `Faltas acumuladas no tempo : ${faultsByTime.length}`;
        } if (faults?.gameTime?.id === 1 && faults?.timeType?.id === 1) {
            return `Faltas acumuladas no tempo : ${faultsByTime.length}`;
        } else if (faults?.gameTime?.id === 2 && faults?.timeType?.id === 1) {

            return `Faltas acumuladas no tempo : ${secondHalfFaults.length}`
        } else if (faults?.gameTime?.id === 1 && faults?.timeType?.id === 2) {
            return `Faltas acumuladas no tempo : ${secondHalfFaults.length + prorrogationFaults.length}`
        } else {
            return `Faltas acumuladas no tempo : ${(secondHalfFaults.length + prorrogationFaults.length + prorrogation2.length + prorrogation3.length + prorrogation4.length)}`
        }*/

    if (faults?.timeType?.id === 1) {
      if (
        faults?.gameTime?.id === 1 ||
        faults?.gameTime?.id === 3 ||
        faults?.gameTime?.id === 4
      ) {
        return `Faltas acumuladas no tempo : ${faultsByTime.length}`;
      } else {
        return `Faltas acumuladas no tempo : ${secondHalfFaults.length}`;
      }
    } else if (faults?.timeType?.id === 2) {
      if (getAllProrrogationTime[0]?.gameTime?.id === faults?.gameTime?.id) {
        return `Faltas acumuladas no tempo : ${
          faultsByLastRegularTime.length + prorrogationFaults.length
        }`;
      } else {
        return `Faltas acumuladas no tempo : ${
          faultsByLastRegularTime.length +
          prorrogationFaults.length +
          totalAllProrrogationTimes.reduce((a, b) => a + b, 0)
        }`;
      }
    } else {
      return `Faltas acumuladas no tempo : ${0}`;
    }
  };

  return (
    <Grid
      item
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h5" className={classes.team}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="h3" className={classes.teamName}>
          {team.name}
        </Typography>
      </Grid>
      <Grid item>
        {!team.colors ? (
          <Box
            style={{
              height: 12,
            }}
          ></Box>
        ) : (
          <Typography variant="h5" className={classes.colors}>
            ({team.colors})
          </Typography>
        )}
      </Grid>

      <Grid item>
        <Typography variant="h5" className={classes.faults}>
          {getFaultsByTime()}
          <br />
          {faults}
        </Typography>
      </Grid>
    </Grid>
  );
}
