import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.action.main,
    "&$checked": {
      color: theme.palette.action.main,
    },
  },
  checked: {},
}));

export default function FCheckBox(props) {
  const classes = useStyles();
  return (
    <Checkbox
      color="default"
      className={classes.root}
      disabled={props.disabled}
      {...props}
    />
  );
}
