import React from "react";
import { Button, useTheme } from "@material-ui/core";

function FButton({ children, type = "primary", className, icon, thin, ...rest }) {
    const theme = useTheme();

    const thinValue = 40;
    const normalValue = 50;

    const minWidth = 61;
    const iconMinWidth = 40;

    const types = [
        {
            id: "main",
            value: {
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.action.main,
                height: thin ? thinValue : normalValue,
                minWidth: icon ? iconMinWidth : minWidth
            },
        },
        {
            id: "primary",
            value: {
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.action.primary,
                height: thin ? thinValue : normalValue,
                minWidth: icon ? iconMinWidth : minWidth
            },
        },
        {
            id: "secondary",
            value: {
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.action.secondary,
                height: thin ? thinValue : normalValue,
                minWidth: icon ? iconMinWidth : minWidth
            },
        },
        {
            id: "warning",
            value: {
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.common.black,
                backgroundColor: theme.palette.warning.main,
                height: thin ? thinValue : normalValue,
                minWidth: icon ? iconMinWidth : minWidth
            },
        },
        {
            id: "danger",
            value: {
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error.main,
                height: thin ? thinValue : normalValue,
                minWidth: icon ? iconMinWidth : minWidth
            },
        },
        {
            id: "cancel",
            value: {
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.action.cancel,
                height: thin ? thinValue : normalValue,
                minWidth: icon ? iconMinWidth : minWidth
            },
        },
        {
            id: "disabled",
            value: {
                fontSize: theme.typography.body1.fontSize,
                color: theme.palette.common.white,
                backgroundColor: theme.palette.action.disabled,
                height: thin ? thinValue : normalValue,
                minWidth: icon ? iconMinWidth : minWidth
            },
        },
    ];

    const buttonStyle =
        rest && rest.disabled
            ? types.find((x) => x.id === "disabled").value
            : types.find((x) => x.id === type).value;

    return (
        <Button
            style={buttonStyle}
            {...rest}
            className={className}
            disableElevation
        >
            {children}
        </Button>
    );
}

export default FButton;
