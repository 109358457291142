import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ErrorAlert from "../../../pops/ErrorAlert";
import constants from "../../../../utils/constants";

const StyledToggleButtonGroup = withStyles((theme) => ({
    root: {
        textTransform: "none",
        color: "white",
        border: "solid 1px white",
        borderRadius: 20,
        width: 250,
        minWidth: 250,
        height: 42,
        "& .MuiToggleButton-root.Mui-selected": {
            textTransform: "none",
            color: "black",
            backgroundColor: "white",
        },
    },
    grouped: {
        textTransform: "none",
        border: "none",
        fontSize: 15,
        fontWeight: 700,
        "&:not(:first-child)": {
            borderRadius: 20,
            width: "70%",
            color: "white",
        },
        "&:first-child": {
            borderRadius: 20,
            width: "70%",
            color: "white",
        },
    },
}))(ToggleButtonGroup);

export default function HeaderToogle({ mainContext }) {

    const [openError, setOpenError] = React.useState(false);
    const index = mainContext.index.toString();

    useEffect(() => {
        setOpenError(prev => mainContext.scalationError);
    }, [mainContext.scalationError])

    const close = () => {
        setOpenError(prev => false);
        mainContext.setScalationError(prev => false);
    }

    return (
        <div>
            {openError &&
                <ErrorAlert
                    open={openError}
                    onClose={close}
                    message={constants.CANNOTGOTOSUMULA}
                ></ErrorAlert>
            }

            <StyledToggleButtonGroup
                size="small"
                value={index}
                exclusive
                onChange={mainContext.selectIndex}
                aria-label="text alignment"
            >
                <ToggleButton value="0" aria-label="left aligned">
                    Escalações
                </ToggleButton>
                <ToggleButton value="1" aria-label="centered">
                    Súmula
                </ToggleButton>
            </StyledToggleButtonGroup>
        </div>
    );
}
