import React from "react";
import theme from "./theme";
import { ThemeProvider } from "@material-ui/styles";
import "./assets/scss/index.scss";

import { Route, Switch } from "react-router-dom";
import Login from "./pages/auth/login/index";
import ForgotPassword from "./pages/auth/forgotPassword/index";
import Match from "./pages/match/index";
import MatchProvider from "./components/contexts/match.provider";
import OccurrencesProvider from "./components/contexts/occurrences.provider";

function App() {
  return (
    <MatchProvider>
      <OccurrencesProvider>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/forgotpassword" component={ForgotPassword} exact />
            <Route path="/match" component={Match} exact />
          </Switch>
        </ThemeProvider>
      </OccurrencesProvider>
    </MatchProvider>
  );
}

export default App;
