import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TechnicalCard from "../cards/technical.card";
import FCard from "../../../../../theme/animations/fcard.animations";
import useTechnicalHook from "../hooks/technical.hook";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: 90,
    },
    title: {
        height: 10,
        fontSize: 16,
        fontWeight: 600,
    },
}));
export default function TechnicalCards({ team }) {
    const classes = useStyles();

    const technicalHook = useTechnicalHook(team);
    var members = technicalHook.members;

    return (
        <Grid
            className={classes.root}
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
        >
            <Grid item xs={12}>
                <Typography className={classes.title} variant="body1">
                    Escalação Titular
                </Typography>
            </Grid>
            <Grid
                container
                item
                spacing={2}
            >
                {members.map((player) => (
                    <FCard key={player.id} values={player.isStarter}>
                        <TechnicalCard player={player}></TechnicalCard>
                    </FCard>
                ))}
            </Grid>
        </Grid>

    );
}
