import { api } from "../services/api.services";

export default function TeamServices() {
  const addPlayer = async (match, team, players) => {
    try {
      let objplayer = {
        matchId: match.id,
        competitionId: match.competition.id,
        teamId: team.id,
        players: players.map((x) => {
          return {
            id: x.id,
            name: x.name,
            nickName: x.nickName,
            birthDate: x.birthDate,
            position: x.position,
            technicalFunction: x.technicalFunction,
            document: x.doc,
            defaultNumber:
              x.defaultNumber !== "" ? parseInt(x.defaultNumber) : undefined,
            inscriptionDate: new Date(),
          };
        }),
      };
      var resp = await api.post("team/addplayertoteam", objplayer);
      return resp.data;
    } catch (error) {
      return error;
    }
  };

  const addTechnical = async (team) => {
    try {
      var resp = await api.post("team/addplayertoteam", team);
      return resp.data;
    } catch (error) {
      return error;
    }
  };

  return {
    addPlayer: addPlayer,
    addTechnical: addTechnical,
  };
}
