import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PlayerCard from "../cards/player.card";
import Typography from "@material-ui/core/Typography";
import FCard from "../../../../../theme/animations/fcard.animations";
import usePlayerHook from "../hooks/players.hook";
//import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingTop: 0,
    },
    grid: {
        minHeight: 230,
        paddingTop: 40,
        paddingBottom: 40,
    },
    title: {
        height: 10,
        fontSize: 16,
        fontWeight: 600,
    },
    starters: {
        backgroundColor: "aliceblue",
    },
    border: {
        backgroundColor: "lightgrey",
    },
}));

export default function PlayerCards({ team, mainContext }) {
    const classes = useStyles();
    const playersHook = usePlayerHook(team);
    const players = [...playersHook.players];

    return (
        <Grid
            className={classes.root}
            container
            direction="column"
            justify="space-between"
            alignItems="stretch"
            spacing={4}
        >
            <Grid container item spacing={3} direction="column" justify="space-between">
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="body1">
                        Escalação Titular
                    </Typography>
                </Grid>

                <Grid container item spacing={2}>
                    {players.sort(playersHook.comparar).map((player) => (
                        <FCard key={player.id} values={player.isStarter}>
                            <PlayerCard playersHook={playersHook} player={player} mainContext={mainContext}></PlayerCard>
                        </FCard>
                    ))}
                </Grid>
            </Grid>
            
            <Grid container item spacing={3} direction="column" justify="space-between">
                <Grid item xs={12}>
                    <Typography className={classes.title} variant="body1">
                        Escalação Reserva
                    </Typography>
                </Grid>

                <Grid container item spacing={2}>
                    {players.sort(playersHook.comparar).map((player) => (
                        <FCard key={player.id} values={player.isReserve}>
                            <PlayerCard playersHook={playersHook} player={player} mainContext={mainContext}></PlayerCard>
                        </FCard>
                    ))}
                </Grid>
            </Grid>

        </Grid>
    );
}
