import { useContext, useCallback, useState, useMemo } from 'react';
import { api } from '../../../../../services/api.services';
import repository from '../../../../../data/repository';
import { OccurrenceContext } from '../../../../../components/contexts/occurrences.provider';
import { MatchContext } from '../../../../../components/contexts/match.provider';
import constants from '../../../../../utils/constants';
import functions from '../../../../../utils/functions';

export default function useOccurrenceHook() {
  const occurrenceContext = useContext(OccurrenceContext);
  const matchContext = useContext(MatchContext);
  const data = useMemo(() => repository(), []);
  const match = matchContext.match;

  const [golIsOpen, setGolIsOpen] = useState(false);
  const [openSingle, setOpenSingle] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);
  const [openSingleTeam, setOpenSingleTeam] = useState(false);
  const [startSubstituition, setStartSubstituition] = useState(false);
  const [startCaptainSubstituition, setStartCaptainSubstituition] =
    useState(false);
  const [parameters, setParameters] = useState({});
  const [changedOccurrences, setChangedOccurrences] = useState([]);

  const getSelectedTeam = useCallback(
    (player) => {
      if (occurrenceContext.teamSelected)
        return { id: occurrenceContext.teamSelected.id };

      if (player) {
        var team = player.position
          ? match.home.players.find((x) => x.id === player.id)
            ? match.home
            : match.away
          : match.home.technicalMembers.find((x) => x.id === player.id)
          ? match.home
          : match.away;
        return { id: team.id };
      }

      return undefined;
    },
    [occurrenceContext.teamSelected, match.home, match.away]
  );

  const buildPayload = useCallback(
    (occurrenceContext, payload, player) => {
      return {
        id: payload.id ? payload.id : 0,
        matchId: match.id,
        competitionId: match.competition.id,
        gameTime: occurrenceContext.activeOccurrenceTime.gameTime,
        timeType: occurrenceContext.activeOccurrenceTime.timeType,
        occurrenceTime:
          payload.occurrenceTime ??
          occurrenceContext.getCalculatedCronometer(payload.occurrenceTime),
        occurrenceType: { id: payload.occurrenceType.id },
        team: getSelectedTeam(player),
        player: player ? { id: player.id, isGol: player.isGol } : undefined,
      };
    },
    [match.competition.id, match.id, getSelectedTeam]
  );

  const buildGolOccurrence = useCallback(
    (occurrences) => {
      let golOccurrence = functions.getGolOccurrenceType();
      let assistenceOccurrence = functions.getAssistenceOccurrenceType();

      if (
        occurrenceContext.selectedPlayers.length === 2 &&
        (occurrences[0].occurrenceType.id === golOccurrence.id ||
          occurrences[0].occurrenceType.id === assistenceOccurrence.id)
      ) {
        occurrences.forEach((occurrence) => {
          occurrence.occurrenceType = occurrence.player.isGol
            ? golOccurrence
            : assistenceOccurrence;
        });
      }
      return occurrences;
    },
    [occurrenceContext.selectedPlayers]
  );

  const buildEndMatchOccurrence = useCallback(() => {
    let payload = {};
    payload.occurrenceType = functions.getEndMatchOccurrenceType();
    let occurrence = buildPayload(occurrenceContext, payload, undefined);
    return occurrence;
  }, [buildPayload, occurrenceContext]);

  const buildOccurrences = useCallback(
    (payload) => {
      let occurrences = [];

      occurrences = occurrenceContext.selectedPlayers.map((player) => {
        return buildPayload(occurrenceContext, payload, player);
      });

      occurrences = buildGolOccurrence(occurrences);

      return occurrences;
    },
    [occurrenceContext, buildPayload, buildGolOccurrence]
  );

  const selectPlayer = (player, team) => {
    occurrenceContext.setTeamSelected(team);

    let allreadySelected = occurrenceContext.selectedPlayers.find(
      (x) => x.id === player.id
    );
    if (allreadySelected) {
      let sp = occurrenceContext.selectedPlayers.filter(
        (x) => x.id !== player.id
      );
      delete player.isGol;
      occurrenceContext.setSelectedPlayers((prev) =>
        occurrenceContext.selectedPlayers.filter((x) => x.id !== player.id)
      );
      if (sp.length === 0) {
        occurrenceContext.setTeamSelected(undefined);
      }
    } else {
      occurrenceContext.setSelectedPlayers([
        ...occurrenceContext.selectedPlayers,
        player,
      ]);
    }
  };

  const selectMultiPlayer = (player) => {
    let allreadySelected = occurrenceContext.selectedPlayers.find(
      (x) => x.id === player.id
    );
    if (allreadySelected) {
      occurrenceContext.setSelectedPlayers((prev) =>
        occurrenceContext.selectedPlayers.filter((x) => x.id !== player.id)
      );
    } else {
      occurrenceContext.setSelectedPlayers([
        ...occurrenceContext.selectedPlayers,
        player,
      ]);
    }
  };

  const selectSinglePlayer = (player, team) => {
    if (startSubstituition) {
      executeSingleSubstituition(player, team);
      return;
    }

    if (startCaptainSubstituition) {
      executeCaptainSubstituition(player, team);
      return;
    }

    let allreadySelected = occurrenceContext.selectedPlayers.find(
      (x) => x.id === player.id
    );
    if (allreadySelected) {
      occurrenceContext.setSelectedPlayers([]);
      occurrenceContext.setTeamSelected(undefined);
      endSubstituition();
      endCaptainSubstituition();
    } else {
      let items = [...occurrenceContext.selectedPlayers];
      items[0] = player;
      occurrenceContext.setSelectedPlayers(items);
      occurrenceContext.setTeamSelected((prev) => team);
    }
  };

  const clearSelectedPlayer = useCallback(() => {
    occurrenceContext.setSelectedPlayers([]);
    occurrenceContext.setTeamSelected(undefined);
  }, [occurrenceContext]);

  const clearSelectedOccurrence = useCallback(() => {
    occurrenceContext.setSelectedOccurrence(undefined);
  }, [occurrenceContext]);

  const getFaults = (teamId, playerId) => {
    return occurrenceContext.occurrences.filter((oc) => {
      return (
        (oc.occurrenceType.id === 12 ||
          oc.occurrenceType.id === 13 ||
          oc.occurrenceType.id === 15 ||
          oc.occurrenceType.id === 18 ||
          oc.occurrenceType.id === 19) &&
        (oc.player.id === playerId || playerId === undefined) &&
        (oc.team.id === teamId || teamId === undefined)
      );
    });
  };

  const getGameTimeId = () => {
    return occurrenceContext.currentOccurrences;
  };

  const getFaultsByTime = (teamId, gameTimeId, timeTypeId, playerId) => {
    return occurrenceContext.occurrences.filter((oc) => {
      return (
        (oc.occurrenceType.id === 12 ||
          oc.occurrenceType.id === 13 ||
          oc.occurrenceType.id === 15 ||
          oc.occurrenceType.id === 18 ||
          oc.occurrenceType.id === 19) &&
        oc.gameTime.id === gameTimeId &&
        oc.timeType.id === timeTypeId &&
        (oc.player.id === playerId || playerId === undefined) &&
        (oc.team.id === teamId || teamId === undefined)
      );
    });
  };
  const getProrrogationFaults = (teamId, gameTimeId, playerId) => {
    return occurrenceContext.occurrences.filter((oc) => {
      return (
        (oc.occurrenceType.id === 12 ||
          oc.occurrenceType.id === 13 ||
          oc.occurrenceType.id === 15 ||
          oc.occurrenceType.id === 18 ||
          oc.occurrenceType.id === 19) &&
        oc.timeType.id === 2 &&
        oc.gameTime.id === gameTimeId &&
        (oc.player.id === playerId || playerId === undefined) &&
        (oc.team.id === teamId || teamId === undefined)
      );
    });
  };

  const getYellowCards = (playerId) => {
    let yellowCards = occurrenceContext.occurrences.find((oc) => {
      return (
        (oc.occurrenceType.id === 9 || oc.occurrenceType.id === 12) &&
        oc.player.id === playerId
      );
    });

    return yellowCards;
  };

  const getRedCards = (playerId) => {
    let redCards = occurrenceContext.occurrences.find((oc) => {
      return (
        (oc.occurrenceType.id === 10 ||
          oc.occurrenceType.id === 13 ||
          oc.occurrenceType.id === 14) &&
        oc.player.id === playerId
      );
    });

    return redCards;
  };

  const isSelected = (player) => {
    return occurrenceContext.selectedPlayers.find((x) => x.id === player.id);
  };

  const selectOccurrence = (occurrence) => {
    if (
      !occurrenceContext.selectedOccurrence ||
      occurrenceContext.selectedOccurrence.id !== occurrence.id
    ) {
      occurrenceContext.setSelectedOccurrence(occurrence);
      return;
    }

    if (occurrenceContext.selectedOccurrence.id === occurrence.id) {
      occurrenceContext.setSelectedOccurrence(undefined);
      return;
    }
  };

  const addToChangeOccurrences = (occurrence) => {
    let alreadyExists = changedOccurrences.find((x) => x.id === occurrence.id);
    if (alreadyExists) {
      setChangedOccurrences(
        changedOccurrences.map((item) =>
          item.id === occurrence.id ? occurrence : item
        )
      );
    } else {
      setChangedOccurrences([...changedOccurrences, occurrence]);
    }
  };

  const clearChangedOccurrences = async () => {
    setChangedOccurrences((prev) => []);
  };

  const requireTime = useCallback(
    (team) => {
      var pedidoTempo = constants.occurrenceTypes.find((x) => x.id === 20);
      let occurrenceRequireTime = buildPayload(
        occurrenceContext,
        { occurrenceType: pedidoTempo },
        undefined
      );
      occurrenceRequireTime.team = { id: team.id };
      return occurrenceRequireTime;
    },
    [buildPayload, occurrenceContext]
  );

  const initSubstituition = () => {
    let player = occurrenceContext.selectedPlayers[0];
    let team = occurrenceContext.teamSelected;

    let currentValue = startSubstituition;

    if (currentValue) {
      endSubstituition();
      return;
    }

    if (!currentValue) {
      let allStarters = team.players.filter(
        (x) => x.isStarterOnTime === true && x.id !== player.id
      );
      allStarters.forEach((p) => {
        p.enableToSubstituition = player.isStarterOnTime ? 0 : 1; // disponíveis
      });

      let allReserves = team.players.filter(
        (x) => x.isReserveOnTime === true && x.id !== player.id
      );
      allReserves.forEach((p) => {
        p.enableToSubstituition = player.isStarterOnTime ? 1 : 0; // disponíveis
      });
    }

    setStartSubstituition((prev) => !currentValue);
  };

  const initCaptainSubstituition = () => {
    let player = occurrenceContext.selectedPlayers[0];
    let team = occurrenceContext.teamSelected;

    let currentValue = startCaptainSubstituition;

    if (currentValue) {
      endCaptainSubstituition(occurrenceContext.occurrences, team);
      return;
    }

    if (!currentValue) {
      let allPlayersOnTime = team.players.filter(
        (x) =>
          (x.isStarterOnTime === true || x.isReserveOnTime === true) &&
          x.id !== player.id
      );
      allPlayersOnTime.forEach((p) => {
        p.enableToSubstituition = 1;
      });
    }

    setStartCaptainSubstituition((prev) => !currentValue);
  };

  const disableToSubstituition = useCallback(() => {
    let team = occurrenceContext.teamSelected;
    team.players.forEach((p) => {
      p.enableToSubstituition = undefined;
    });
  }, [occurrenceContext.teamSelected]);

  const endSubstituition = useCallback(() => {
    disableToSubstituition();
    setStartSubstituition((prev) => false);
  }, [disableToSubstituition]);

  const endCaptainSubstituition = useCallback(
    (occurrences, teamSelected) => {
      if (occurrences && teamSelected) {
        let team = teamSelected.id === match.home.id ? match.home : match.away;
        occurrences.forEach((occurrence) => {
          let player = team.players.find((x) => x.id === occurrence.player.id);
          player.isCaptain = occurrence.occurrenceType.id === 25;
        });
      }
      disableToSubstituition();
      matchContext.updateMatch();
      setStartCaptainSubstituition((prev) => false);
    },
    [disableToSubstituition, match.home, match.away, matchContext]
  );

  const executeSingleSubstituition = (player, team) => {
    // Verifico se é do mesmo time
    if (occurrenceContext.teamSelected.id !== team.id) return;

    var selectedPlayer = occurrenceContext.selectedPlayers[0];
    if (selectedPlayer.id === player.id) return;
    var starterPlayer = player.isStarterOnTime ? player : selectedPlayer;
    var reservePlayer = player.isReserveOnTime ? player : selectedPlayer;

    clearSelectedPlayer();

    var sai = constants.occurrenceTypes.find((x) => x.id === 21);
    var entra = constants.occurrenceTypes.find((x) => x.id === 22);

    let occurrenceSai = buildPayload(
      occurrenceContext,
      { occurrenceType: sai },
      starterPlayer
    );
    let occurrenceEntra = buildPayload(
      occurrenceContext,
      { occurrenceType: entra },
      reservePlayer
    );

    let occurrences = [occurrenceSai, occurrenceEntra];

    customDispatch({ type: 'executeSubstituition', payload: occurrences });
  };

  const executeCaptainSubstituition = (player, team) => {
    // Verifico se é do mesmo time
    if (occurrenceContext.teamSelected.id !== team.id) return;

    var selectedPlayer = occurrenceContext.selectedPlayers[0];
    if (selectedPlayer.id === player.id) return;
    var actualCaptianPlayer = player.isCaptain ? player : selectedPlayer;
    var newCaptianPlayer = !player.isCaptain ? player : selectedPlayer;

    clearSelectedPlayer();

    var sai = constants.occurrenceTypes.find((x) => x.id === 24);
    var entra = constants.occurrenceTypes.find((x) => x.id === 25);

    let occurrenceSai = buildPayload(
      occurrenceContext,
      { occurrenceType: sai },
      actualCaptianPlayer
    );
    let occurrenceEntra = buildPayload(
      occurrenceContext,
      { occurrenceType: entra },
      newCaptianPlayer
    );

    let occurrences = [occurrenceSai, occurrenceEntra];

    customDispatch({
      type: 'executeCaptainSubstituition',
      payload: occurrences,
    });
  };

  const handleMVP = (playerId, matchId) => {
    customDispatch({
      type: 'setMVP',
      payload: {
        playerId,
        matchId,
      },
    });
  };

  const customDispatch = useCallback(
    async (action) => {
      // Construo a ocorrência padrão
      occurrenceContext.setIsLoadingOccurrences(true);
      let occurrences = [];
      let response;
      let deleteAll = action?.payload?.deleteAll ? true : false;

      switch (action.type) {
        case 'addOccurrence':
          occurrences = buildOccurrences(action.payload);
          response = await api.post('occurrence', { occurrences: occurrences });
          if (response.status === 200) {
            occurrenceContext.addOccurrence(response.data);
            clearSelectedPlayer();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'editOccurrence':
          let occurrence = buildOccurrences(action.payload);
          response = await api.put('occurrence', { occurrences: occurrence });
          if (response.status === 200) {
            occurrenceContext.editNewOccurrences(response.data, deleteAll);
            clearSelectedPlayer();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'editChangedOccurrences':
          response = await api.put('occurrence', {
            occurrences: action.payload,
          });
          if (response.status === 200) {
            occurrenceContext.editNewOccurrences(response.data);
            clearSelectedPlayer();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'deleteOccurrence':
          response = await api.delete(
            `occurrence/${action.payload.id}/${deleteAll}`
          );
          if (response.status === 200) {
            await occurrenceContext.deleteOccurrence(action.payload, deleteAll);
            clearSelectedOccurrence();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'requireTime':
          response = await api.post('occurrence', {
            occurrences: [requireTime(action.payload)],
          });
          if (response.status === 200) {
            occurrenceContext.addOccurrence(response.data);
            clearSelectedPlayer();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'executeSubstituition':
          response = await api.post('occurrence', {
            occurrences: action.payload,
          });
          if (response.status === 200) {
            occurrenceContext.addOccurrence(response.data);
            clearSelectedPlayer();
            endSubstituition();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'executeCaptainSubstituition':
          response = await api.post('occurrence', {
            occurrences: action.payload,
          });
          if (response.status === 200) {
            occurrenceContext.addOccurrence(response.data);
            clearSelectedPlayer();
            endCaptainSubstituition(
              action.payload,
              occurrenceContext.teamSelected
            );
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'setMVP':
          response = await api.put('match/set-mvp', action.payload);
          if (response.status === 200) {
            if (!match.mostValuablePlayer) {
              data.setItemStringify(constants.MATCH, {
                ...match,
                mostValuablePlayer: { id: action.payload.playerId },
              });
              matchContext.setMatch({
                ...match,
                mostValuablePlayer: { id: action.payload.playerId },
              });
            } else {
              match.mostValuablePlayer.id = action.payload.playerId;
              data.setItemStringify(constants.MATCH, match);
              matchContext.setMatch(match);
            }
            clearSelectedPlayer();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        case 'endMatch':
          response = await api.post('occurrence', {
            occurrences: [buildEndMatchOccurrence()],
          });
          if (response.status === 200) {
            occurrenceContext.addOccurrence(response.data);
            clearSelectedPlayer();
          }
          occurrenceContext.setIsLoadingOccurrences(false);
          break;

        default:
      }
    },
    [
      occurrenceContext,
      buildOccurrences,
      requireTime,
      buildEndMatchOccurrence,
      clearSelectedPlayer,
      clearSelectedOccurrence,
      endSubstituition,
      endCaptainSubstituition,
      matchContext,
      data,
    ]
  );

  return {
    // ----------------------------------------------------------
    // Partida
    // ----------------------------------------------------------
    match: match,

    // ----------------------------------------------------------
    // Ocorrências selecionadas
    // ----------------------------------------------------------
    currentOccurrences: occurrenceContext.currentOccurrences,
    occurrences: occurrenceContext.occurrences,

    isLoadingOccurrences: occurrenceContext.isLoadingOccurrences,
    dispatch: customDispatch,

    golIsOpen,
    setGolIsOpen,
    openSingle,
    setOpenSingle,
    openMulti,
    setOpenMulti,
    openSingleTeam,
    setOpenSingleTeam,
    parameters,
    setParameters,
    editOccurrence: occurrenceContext.editOccurrence,

    initSubstituition: initSubstituition,
    initCaptainSubstituition: initCaptainSubstituition,

    selectPlayer: selectPlayer,
    selectSinglePlayer: selectSinglePlayer,
    selectMultiPlayer: selectMultiPlayer,
    isSelected: isSelected,
    clearSelectedPlayer: clearSelectedPlayer,
    clearSelectedOccurrence: clearSelectedOccurrence,

    selectedPlayers: occurrenceContext.selectedPlayers,
    teamSelected: occurrenceContext.teamSelected,
    setSelectedPlayers: occurrenceContext.setSelectedPlayers,

    activeOccurrenceTime: occurrenceContext.activeOccurrenceTime,
    setActiveOccurrenceTime: occurrenceContext.setActiveOccurrenceTime,

    setSelectedPlayer: occurrenceContext.setSelectedPlayer,
    setTeamSelected: occurrenceContext.setTeamSelected,

    sanatizeUnit: occurrenceContext.sanatizeUnit,
    cronometerValue: occurrenceContext.cronometerValue,

    changedOccurrences,
    addToChangeOccurrences,
    clearChangedOccurrences,

    selectedOccurrence: occurrenceContext.selectedOccurrence,
    selectOccurrence: selectOccurrence,

    getFaults: getFaults,
    getGameTimeId: getGameTimeId,
    getFaultsByTime: getFaultsByTime,
    getProrrogationFaults: getProrrogationFaults,
    getGols: occurrenceContext.getGoalsInfo,
    getYellowCards: getYellowCards,
    getRedCards,
    getCalculatedCronometer: occurrenceContext.getCalculatedCronometer,
    handleMVP,
  };
}
