import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

// My Components
import FButton from "../../../../../components/button/FButton";
import FSelect from "../../../../../components/inputs/FSelect";
import FTimeInput from "../../../../../components/inputs/FTimeInput";
import Confirm from "../../../../../components/pops/Confirm";
import FController from "../../../../../components/inputs/FControllerHook";
import CloseButton from '../../../../../components/button/CloseButton'

import constants from "../../../../../utils/constants";
const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
        position: 'relative',
        paddingTop: 20,
        [theme.breakpoints.up("lg")]: {
            maxWidth: 850,
            minWidth: 510,
        },
    },
    body: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    title: {
        paddingBottom: theme.spacing(1),
    },
    subtitle: {
        color: theme.palette.action.cancel,
    },
    spacer: { width: 45 },
    inactive: {
        "& > *": { color: "lightgrey !important" },
        "& > td > *": { color: "lightgrey !important" },
    },

    actions: {
        display: "flex",
        paddingRight: theme.spacing(0),
        paddingBottom: theme.spacing(2),
        justifyContent: "space-between",
    },
    cancel: {
        marginRight: theme.spacing(2),
    },
    buttons: {
        minWidth: 215,
    },
    row: {
        height: 55,
    },
}));

export default function PopEditTime({
    period,
    open,
    handleCancel,
    handleOk,
    occurrencePeriodsHook,
    handleDelete,
}) {
    // Styles
    const classes = useStyles();
    const [editPeriod, setEditPeriod] = useState(period);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const startHour = FController();
    const endHour = FController();

    const handleSetTimeType = (value) => {
        setEditPeriod({ ...editPeriod, timeType: value, });
    };

    const handleSetGameTime = (value) => {
        setEditPeriod({ ...editPeriod, gameTime: value, });
    };

    const ok = () => {
        if (validate()) {
            handleOk();
            occurrencePeriodsHook.dispatch({ type: 'editPeriod', payload: editPeriod });
        }
    };

    const cancel = () => {
        handleCancel();
    };

    const isEnabled = () => {
        return (
            (editPeriod.timeType &&
                editPeriod.timeType.id &&
                editPeriod.gameTime.id) ||
            isPenaltis()
        );
    };

    const isPenaltis = () => {
        return editPeriod.timeType && editPeriod.timeType.id === 3;
    };

    const cannotChange = () => {
        if (editPeriod.timeType.id)
        return editPeriod.gameTime.id <= 2 && editPeriod.timeType.id === 1;
    };

    const handleDeleteOk = () => {
        occurrencePeriodsHook.dispatch({ type: 'deletePeriod', payload: editPeriod });
        handleOk();
        setConfirmDelete(true);
        handleDelete();
    };

    const updateStart = (value) => {
        value = value === '' ? '--:--' : value;
        setEditPeriod({ ...editPeriod, occurrenceHourStart: value, });
    }

    const updateEnd = (value) => {
        value = value === '' ? '--:--' : value;
        setEditPeriod({ ...editPeriod, occurrenceHourEnd: value, });
    }

    const handleDeleteCancel = () => {
        setConfirmDelete(false);
    };

    const validate = () => {

        startHour.setError(undefined);
        endHour.setError(undefined);
        let defaultHour = '--:--'

        var validHourStart = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(editPeriod.occurrenceHourStart);
        var validHourEnd = /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(editPeriod.occurrenceHourEnd);
        var message = 'Hora inválida';

        if (!validHourStart && editPeriod.occurrenceHourStart !== defaultHour) {
            startHour.setError(message);
            return false;
        }

        if (!validHourEnd && editPeriod.occurrenceHourEnd !== defaultHour) {
            endHour.setError(message);
            return false;
        }

        if (editPeriod.occurrenceHourStart === defaultHour || editPeriod.occurrenceHourEnd !== defaultHour) {
            return true;
        }

        return validateHours(editPeriod.occurrenceHourStart, editPeriod.occurrenceHourEnd);
    }

    const validateHours = (occurrenceHourStart, occurrenceHourEnd) => {
        const init = new Date();
        const end = new Date();

        var initTotalHour = occurrenceHourStart.split(':');
        init.setHours(initTotalHour[0]);
        init.setMinutes(initTotalHour[1]);

        var endTotalHour = occurrenceHourEnd.split(':');
        end.setHours(endTotalHour[0]);
        end.setMinutes(endTotalHour[1]);

        let result = init.getTime() !== end.getTime();
        
        return result;
    }

    return (
        <div>
            {confirmDelete && (
                <Confirm
                    open={confirmDelete}
                    title="Excluir Tempo"
                    handleOk={handleDeleteOk}
                    handleAbort={handleDeleteCancel}
                    message="Deseja realmente excluir esse Período de Tempo? Todos os eventos dentro desse período TAMBÉM serão excluidos"
                />
            )}
            <Dialog
                classes={{ paper: classes.dialogPaper }}
                open={open}
                onClose={cancel}
                maxWidth="sm"
                disableBackdropClick={true}
            >
                <CloseButton onClick={cancel} />
                <Grid
                    container
                    className={classes.root}
                    justify="flex-start"
                    direction="column"
                >
                    <Grid container justify="space-between">
                        <Grid item xs={6}>
                            <Typography variant="h4" className={classes.title}>
                                Editar Tempo
                            </Typography>
                        </Grid>
                        <Grid item container justify="flex-end" xs={6}>
                            <Typography variant="h4" className={classes.subtitle}>
                                {editPeriod.gameTime.name} - {editPeriod.timeType.name}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider></Divider>
                    <Grid
                        className={classes.body}
                        item
                        container
                        direction="column"
                        spacing={2}
                    >
                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <FSelect
                                    // disabled={cannotChange()}
                                    disabled={true}
                                    placeHolder="Selecione o tempo"
                                    value={editPeriod.timeType || ""}
                                    collection={constants.timeType}
                                    onChange={(event) => {
                                        handleSetTimeType(event);
                                    }}
                                ></FSelect>
                            </Grid>
                            <Grid item xs={6}>
                                <FSelect
                                    // disabled={isPenaltis() || cannotChange()}
                                    disabled={true}
                                    placeHolder="Selecione o período"
                                    value={editPeriod.gameTime}
                                    collection={constants.gameTimeEvents}
                                    onChange={(event) => {
                                        handleSetGameTime(event);
                                    }}
                                ></FSelect>
                            </Grid>
                        </Grid>
                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <FTimeInput
                                    value={editPeriod.occurrenceHourStart}
                                    controller={startHour}
                                    onChange={(event) => {
                                        updateStart(event);
                                    }}></FTimeInput>
                            </Grid>
                            <Grid item xs={6}>
                                <FTimeInput
                                    value={editPeriod.occurrenceHourEnd}
                                    controller={endHour}
                                    onChange={(event) => {
                                        updateEnd(event);
                                    }}></FTimeInput>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.actions}>
                        <FButton
                            disabled={cannotChange()}
                            thin={true}
                            fullWidth
                            onClick={() => setConfirmDelete(true)}
                            type="danger"
                        >
                            Excluir
                        </FButton>
                        <div className={classes.spacer}></div>
                        <FButton thin={true} fullWidth onClick={cancel} type="cancel">
                            Cancelar
                        </FButton>
                        <div className={classes.spacer}></div>
                        <FButton
                            disabled={!isEnabled()}
                            thin={true}
                            fullWidth
                            onClick={ok}
                            type="main"
                        >
                            Confirmar
                        </FButton>
                    </div>
                </Grid>
            </Dialog>
        </div>
    );
}
