export default function Validators() {
  const isRequiredSimple = (value) => {
    if (value == null || value === "") {
      return "*";
    }
    return "";
  };
  const isRequired = (value) => {
    if (value == null || value === "") {
      return "Por favor preencha o Campo";
    }
    return "";
  };

  const isRequiredEmail = (value) => {
    var ok = isRequired(value);
    if (ok.length > 0) {
      return "Por favor informe o Email";
    }
    return ok;
  };

  const isRequiredPassword = (value) => {
    var ok = isRequired(value);
    if (ok.length > 0) {
      return "Por favor informe a Senha";
    }
    return ok;
  };

  const isRequiredToken = (value) => {
    var ok = isRequired(value);
    if (ok.length > 0) {
      return "Por favor informe a Senha";
    }
    return ok;
  };

  return {
    isRequiredSimple: isRequiredSimple,
    isRequired: isRequired,
    isRequiredEmail: isRequiredEmail,
    isRequiredPassword: isRequiredPassword,
    isRequiredToken: isRequiredToken,
  };
}
