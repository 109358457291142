/* eslint-disable no-restricted-globals */

const workercode = () => {
  let cron;
  let minute = 0;
  let second = 0;
  let millisecond = 0;

  self.onmessage = function ({ data: { play, currentTime, type } }) {
    const start = () => {
      pause();
      cron = setInterval(() => {
        timer();
      }, 10);
    };
    const pause = () => {
      clearInterval(cron);
    };

    const returnData = (input) => {
      return input >= 10 ? input : `0${input}`;
    };

    function timer() {
      if (type === 1) {
        if ((millisecond += 10) === 1000) {
          millisecond = 0;
          second++;

          self.postMessage({
            timerMinutes: `${returnData(minute)}:${returnData(second)}`,
          });
        }

        if (second === 60) {
          second = 0;
          minute++;
          self.postMessage({
            timerMinutes: `${returnData(minute)}:00`,
          });
        }
      } else {
        if ((millisecond += 10) === 1000) {
          millisecond = 0;
          second--;

          self.postMessage({
            timerMinutes: `${returnData(minute)}:${returnData(second)}`,
          });
        }

        if (second === 0) {
          second = 60;
          minute--;
        }
      }
    }

    if (play === 'off' || cron) {
      pause();
      clearInterval(cron);
    }
    if (play === 'on' && currentTime) {
      minute = Number(currentTime.split(':')[0]);
      second = Number(currentTime.split(':')[1]);
      start();
    }
  };
};

let code = workercode.toString();
const blob = new Blob(['(' + code + ')()']);
const worker_script = URL.createObjectURL(blob);

export default worker_script;
