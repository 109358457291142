import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.action.main,
    "&$checked": {
      color: theme.palette.action.main,
    },
  },
  checked: {},
}));

export default function FRadio(props) {
  const classes = useStyles();
  return <Radio color="default" className={classes.root} {...props} />;
}
