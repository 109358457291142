import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// My custom components
import constants from '../../../../../utils/constants';
import functions from '../../../../../utils/functions';
import FCheckBox from '../../../../../components/checkbox/FCheckBox';
import useSearchPlayersHook from '../hooks/search.hook';
import repository from '../../../../../data/repository';
import FButton from '../../../../../components/button/FButton';
import ButtonDocument from '../../../../../components/button/ButtonDocuments';
import useTechnicalHook from '../hooks/technical.hook';
import { MatchContext } from '../../../../../components/contexts/match.provider';
import PopAddPlayer from '../../pops/addplayers/index';
import EnhancedTableHead from '../table/head';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  table: {
    width: '100%',
    minWidth: 550,
    overflow: 'hidden',
  },
  nickname: {
    fontSize: 15,
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  nicknameOnName: {
    fontSize: 15,
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  nicknamecell: {
    paddingRight: 25,
    maxWidth: 120,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  docscell: {
    paddingRight: 25,
    maxWidth: 120,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  defaultNumber: {
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 10,
  },
  ok: {
    color: 'green',
    fontWeight: 600,
  },
  nook: {
    color: 'red',
    fontWeight: 600,
  },
  na: {
    color: 'grey',
    fontWeight: 600,
    paddingRight: 20,
  },
  namecell: {
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10,
    wordBreak: 'break-word',
  },
  technicalFunction: {
    minWidth: 200,
  },
  gridSpacer: {
    paddingTop: theme.spacing(5),
  },
  row: {},
  search: {
    color: 'lightgrey',
  },
  check: {
    marginRight: 25,
  },
  documentation: {
    maxWidth: 100,
    textAlign: 'center',
  },
  checkColumn: {
    maxWidth: 50,
    textAlign: 'center',
  },
  newMember: {
    maxWidth: 350,
  },
}));

export default function TechnicalTable({ team }) {
  // Styles
  const classes = useStyles();
  const mainContext = useContext(MatchContext);

  // States
  const technicalHook = useTechnicalHook(team);
  const [members, setMembers] = useState(() => team.technicalMembers);
  const useSearch = useSearchPlayersHook();
  const [openAddPlayer, setOpenAddPlayer] = useState(false);
  const repositoryData = repository();

  useEffect(() => {
    //--------------------------------------------------
    // Sem esse useEffect, ao adicionar um segundo player
    // a lista, ela NÃO É ATUALIZADA. ???????????
    //--------------------------------------------------
  }, [members]);

  // Methods
  const openAddPlayerHandler = () => {
    setOpenAddPlayer(true);
  };

  const closeAddHandler = () => {
    setOpenAddPlayer(false);
  };

  const handleSearch = (event) => {
    const result = useSearch.search(event.target.value, technicalHook.members);
    setMembers(result);
  };

  const update = () => {
    setMembers(technicalHook.members);
    mainContext.updateMatch();
  };

  const getTechnicalFunction = (technicalFunction) => {
    let tf = constants.technicalFunctions.find(
      (x) => x.id === technicalFunction.id
    );

    return tf;
  };

  const buildPlayerTechnicalFunction = (
    playerFunction,
    playerCumulativeFunctions
  ) => {
    // Primeiro acho a minha função
    let myFunction = getTechnicalFunction(playerFunction);

    // Depois verifico se minha função está dentro
    // das minhas funções acumulativas, senão estiver
    // eu preciso adicionar pra aparecer na combo
    let exists = playerCumulativeFunctions.find(
      (x) => x.id === playerFunction.id
    );

    if (!exists) playerCumulativeFunctions.push(myFunction);

    // agora retorno todas as minhas funções possíveis
    let fts = [];

    playerCumulativeFunctions.forEach((f) => {
      let fExists = constants.technicalFunctions.find((x) => x.id === f.id);
      fts.push(fExists);
    });
    return fts;
  };

  //--------------------------------------------------
  // FILTRO
  //--------------------------------------------------
  const handleRequestSort = async (event, property) => {
    const isAsc =
      technicalHook.orderBy === property && technicalHook.order === 'asc';
    technicalHook.setOrder(isAsc);
    technicalHook.setOrderBy(property);
  };

  const descendingComparator = (a, b, orderBy) => {
    // ordenação pela posição
    if (orderBy === 'technicalFunction') {
      return comparatorPosition(a, b, orderBy);
    }

    return comparatorNormal(a, b, orderBy);
  };

  const comparatorNormal = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
  };

  const comparatorPosition = (a, b, orderBy) => {
    if (b.filter[orderBy].name < a.filter[orderBy].name) {
      return -1;
    }
    if (b.filter[orderBy].name > a.filter[orderBy].name) {
      return 1;
    }
  };

  const getComparator = () => {
    return technicalHook.order === 'desc'
      ? (a, b) => descendingComparator(a, b, technicalHook.orderBy)
      : (a, b) => -descendingComparator(a, b, technicalHook.orderBy);
  };

  const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };
  //--------------------------------------------------
  // FILTRO
  //--------------------------------------------------

  const handleDocAction = async (technicalId, status) => {
    const updatedMatch = mainContext.match;
    if (status === 'OK') {
      const listMembers = members.map((p) =>
        p.id === technicalId
          ? {
              ...p,
              docsVerified: {
                id: 1,
                name: 'OK',
              },
            }
          : p
      );
      setMembers(listMembers);
      if (mainContext.step === 0) {
        updatedMatch.home.technicalMembers = listMembers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      } else {
        updatedMatch.away.technicalMembers = listMembers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      }
    } else {
      const listMembers = members.map((p) =>
        p.id === technicalId
          ? {
              ...p,
              docsVerified: {
                id: 2,
                name: 'Pendente',
              },
            }
          : p
      );
      setMembers(listMembers);
      if (mainContext.step === 0) {
        updatedMatch.home.technicalMembers = listMembers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      } else {
        updatedMatch.away.technicalMembers = listMembers;
        repositoryData.setItemStringify(constants.MATCH, updatedMatch);
      }
    }
  };

  return (
    <div className={classes.root}>
      {/* Pop para adição de Jogador */}
      {openAddPlayer && (
        <PopAddPlayer
          team={team}
          match={mainContext.match}
          updateCaller={update}
          open={openAddPlayer}
          step={mainContext.step}
          closeHandler={closeAddHandler}
          addType={constants.AddType.TECHNICAL}
        ></PopAddPlayer>
      )}

      <Grid container direction="column">
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item lg={4} xs={4} sm={6}>
            <TextField
              variant="outlined"
              margin="dense"
              fullWidth
              placeholder="Pesquisar"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Search className={classes.search} />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sm={5}
            container
            justify="flex-end"
            className={classes.newMember}
          >
            <FButton
              thin
              fullWidth
              type="primary"
              onClick={openAddPlayerHandler}
              disabled={mainContext.isMatchDone}
            >
              Inscrever membro no time
            </FButton>
          </Grid>
        </Grid>
        <Grid item className={classes.gridSpacer}>
          <TableContainer>
            <Table className={classes.table} aria-label="Scalation Table">
              <EnhancedTableHead
                classes={classes}
                order={technicalHook.order}
                orderBy={technicalHook.orderBy}
                onRequestSort={handleRequestSort}
                headCells={constants.technicalHeadCells}
              />

              <TableBody>
                {stableSort(members, getComparator()).map((player) => (
                  <TableRow key={player.id} className={classes.row}>
                    {/* Apelido */}
                    <TableCell className={classes.nicknamecell}>
                      <Typography variant="h4" className={classes.nickname}>
                        {functions.setNicknameWith10(player)}
                      </Typography>
                    </TableCell>

                    <TableCell className={classes.docscell}>
                      <ButtonDocument
                        documentId={player.id}
                        isVeryfiDoc={player.docsVerified?.name === 'OK'}
                        handleAction={(status) =>
                          handleDocAction(player.id, status)
                        }
                      />
                    </TableCell>

                    {/* Nome do Jogador */}
                    <TableCell className={classes.namecell}>
                      <Typography
                        variant="h4"
                        className={classes.nicknameOnName}
                      >
                        {functions.setNicknameWith10(player)}
                      </Typography>
                      {player?.name}
                      <Typography variant="body2" className={classes.nascRG}>
                        <strong>Nasc: </strong>
                        {functions.formatDate(player.birthDate)}
                        {' - '}
                        <strong>Rg: </strong> {player.document}
                        {' - '}
                        <strong>Insc: </strong>
                        {functions.formatDate(player.inscriptionDate)}
                      </Typography>
                    </TableCell>

                    {/* Função */}
                    <TableCell className={classes.technicalFunction}>
                      <Select
                        disabled={mainContext.isMatchDone}
                        variant="outlined"
                        fullWidth
                        value={getTechnicalFunction(player.technicalFunction)}
                        onChange={(e) => {
                          technicalHook.changeFunction(
                            player,
                            getTechnicalFunction(e.target.value)
                          );
                        }}
                      >
                        {buildPlayerTechnicalFunction(
                          player.technicalFunction,
                          player.cumulativeFunctions
                        ).map((item) => (
                          <MenuItem key={item.id} value={item}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>

                    {/* Escalar */}
                    <TableCell align="center" className={classes.checkColumn}>
                      <FCheckBox
                        disabled={mainContext.isMatchDone}
                        className={classes.check}
                        onChange={(event) => {
                          technicalHook.setStarter(
                            player,
                            event.target.checked
                          );
                        }}
                        checked={player.isStarter === true}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
}
