import React, { useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";

// My components
import FButton from "../../../../../components/button/FButton";
import FTextInput from "../../../../../components/inputs/FTextInput";
import LoadingBlock from '../../../../../components/loading/loadingBlock'
import CloseButton from '../../../../../components/button/CloseButton'

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },
    dialogPaper: {
        position: 'relative',
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        maxWidth: 800,
        minWidth: 800,
        paddingTop: 10,
    },
    body: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        maxHeight: "67vh",
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    input: {
        "& .MuiOutlinedInput-root": {
            height: 350
        },
    },
    actions: {
        display: "flex",
        paddingRight: theme.spacing(0),
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2),
        justifyContent: "flex-end",
    },
    spacer: { width: 20 },
    cancel: {
        marginRight: theme.spacing(2),
    },
    buttons: {
        width: 190
    },
}));


export default function RefeereReport({ refereeReportHook, mainContext }) {

    // Styles
    const classes = useStyles();
    const [comment, setComment] = useState(refereeReportHook.comment);

    const save = () => {
        refereeReportHook.save(comment);
    }

    return (
        <div>
            <Dialog
                classes={{ paper: classes.dialogPaper }}
                open={refereeReportHook.showReport}
                maxWidth="sm"
                onClose={() => refereeReportHook.setShowReport(false)}
                disableBackdropClick={true}
            >
                <CloseButton onClick={() => refereeReportHook.setShowReport(false)} />
                <Grid
                    container
                    className={classes.root}
                    justify="flex-start"
                    direction="column"

                >
                    <Grid container justify="space-between">
                        <Grid item xs={6}>
                            <Typography variant="h4" className={classes.title}>
                                Relatório do árbitro
                            </Typography>
                        </Grid>

                    </Grid>
                    <Divider />
                    <Grid container item direction="row" justify="center" className={classes.body}>

                        <LoadingBlock loading={refereeReportHook.loading}>
                            <FTextInput
                                disabled={mainContext.isMatchDone}
                                value={comment}
                                className={classes.input}
                                label="Relatório do árbrito"
                                multiline
                                maxlength={10000}
                                rows={20}
                                onChange={((value) => {
                                    setComment(value);
                                })}
                            ></FTextInput>
                        </LoadingBlock>
                    </Grid>


                    <div className={classes.actions}>
                        <FButton
                            thin={true}
                            disabled={refereeReportHook.loading}
                            onClick={() => refereeReportHook.setShowReport(false)}
                            className={classes.buttons}
                            type="cancel">
                            Cancelar
                        </FButton>
                        <div className={classes.spacer}></div>
                        <FButton
                            disabled={refereeReportHook.loading || mainContext.isMatchDone}
                            thin={true}
                            className={classes.buttons}
                            type="main"
                            onClick={save}
                        >
                            Confirmar
                        </FButton>
                    </div>
                </Grid>
            </Dialog>
        </div>
    )
}


