export default function useSearchPlayersHook() {
  const search = (searchTerm, players) => {
    return players.filter(
      (player) =>
        // Nome
        player.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // Apelido
        (player.nickName &&
          player.nickName.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  };

  return {
    search: search,
  };
}
