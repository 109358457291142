import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";


export default function LoadingBox(props) {
    return (
        <>
            {props.loading &&
                <>
                    <CircularProgress ></CircularProgress>
                    <br></br></>
            }

            {!props.loading &&
                <>
                    {props.children}
                </>
            }

        </>
    );
}