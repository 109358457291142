import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRight from "@material-ui/icons/ArrowForward";
import { MatchContext } from "../../../contexts/match.provider";
import { OccurrenceContext } from "../../../contexts/occurrences.provider";

const useStyles = makeStyles((theme) => ({
  root: {
    borderColor: "white",
    fontWeight: 600,
    color: "white",
    padding: 8,
    width: 100,
  },
}));

// My components

export default function Logout() {
  const classes = useStyles();
  const mainContext = useContext(MatchContext);
  const occurrenceContext = useContext(OccurrenceContext);

  const logout = () => {
    occurrenceContext.logout();
    mainContext.logout();
  };

  return (
    <Button
      className={classes.root}
      variant="outlined"
      onClick={logout}
      color="default"
      endIcon={<ArrowRight></ArrowRight>}
    >
      Sair
    </Button>
  );
}
