export default {
  // STRINGS
  SCALATIONSTEP: 'scalationStep',
  INDEX: 'index',
  MATCH: 'match',
  OCCURRENCES: 'occurrences',
  PERIODS: 'periods',
  USER: 'user',
  REFEREES: 'referees',
  ACTIVETIME: 'activeTime',
  TIMER: 'timer',

  // ENUMS
  AddType: {
    PLAYER: 'player',
    TECHNICAL: 'technical',
    REFEREE: 'referee',
  },

  // COLLECTIONS
  //   modalityIds => 1 = Futsal, 2 = Futebol de campo, 3 = Futebol 7, 4 = Society, 5 = Futebol de areia
  positions: [
    {
      modalityId: 1,
      positions: [
        { id: 1, name: 'Goleiro' },
        { id: 2, name: 'Ala' },
        { id: 3, name: 'Pivô' },
        { id: 4, name: 'Fixo' },
      ],
    },
    {
      modalityId: 2,
      positions: [
        { id: 1, name: 'Goleiro' },
        { id: 10, name: 'Zagueiro' },
        { id: 5, name: 'Lateral' },
        { id: 11, name: 'Volante' },
        { id: 8, name: 'Meio Campo' },
        { id: 9, name: 'Atacante' },
        { id: 12, name: 'Centroavante' },
      ],
    },
    {
      modalityId: 3,
      positions: [
        { id: 1, name: 'Goleiro' },
        { id: 10, name: 'Zagueiro' },
        { id: 5, name: 'Lateral' },
        { id: 8, name: 'Meio Campo' },
        { id: 9, name: 'Atacante' },
      ],
    },
    {
      modalityId: 4,
      positions: [
        { id: 1, name: 'Goleiro' },
        { id: 10, name: 'Zagueiro' },
        { id: 5, name: 'Lateral' },
        { id: 8, name: 'Meio Campo' },
        { id: 9, name: 'Atacante' },
      ],
    },
    {
      modalityId: 5,
      positions: [
        { id: 1, name: 'Goleiro' },
        { id: 10, name: 'Zagueiro' },
        { id: 9, name: 'Atacante' },
      ],
    },
  ],

  technicalFunctions: [
    { id: 5, name: 'Representante', sigla: 'Rp', shortName: 'Rep.' },
    { id: 6, name: 'Médico', sigla: 'Dr', shortName: 'Médico(a)' },
    { id: 7, name: 'Técnico', sigla: 'Tc', shortName: 'Técnico(a)' },
    { id: 8, name: 'Massagista', sigla: 'Mg', shortName: 'Massagista' },
    {
      id: 9,
      name: 'Analista Desempenho',
      sigla: 'Ad',
      shortName: 'A. Desemp.',
    },
    { id: 10, name: 'Preparador Físico', sigla: 'Pf', shortName: 'P. Físico' },
    {
      id: 11,
      name: 'Auxiliar Técnico',
      sigla: 'At',
      shortName: 'A. Técnico',
    },
    {
      id: 12,
      name: 'Preparador Goleiro',
      sigla: 'Pg',
      shortName: 'P. Goleiro',
    },
    { id: 13, name: 'Fisioterapeuta', sigla: 'Fs', shortName: 'Fisio' },
    { id: 14, name: 'Supervisor', sigla: 'Sp', shortName: 'Sup.' },
  ],

  refereesFunctions: [
    {
      id: 1,
      name: 'Árbitro Principal',
      sigla: 'AP',
      shortName: 'A. Principal',
    },
    { id: 2, name: 'Árbitro Auxiliar', sigla: 'AA', shortName: 'A. Auxiliar' },
    { id: 3, name: 'Assistente 01', sigla: 'A1', shortName: 'Ass. 1' },
    { id: 4, name: 'Assistente 02', sigla: 'A2', shortName: 'Ass. 2' },
    { id: 5, name: 'Anotador', sigla: 'AN', shortName: 'An.' },
    { id: 6, name: 'Cronometrista', sigla: 'Cr', shortName: 'Crono' },
    { id: 7, name: 'Representante', sigla: 'Rp', shortName: 'Rep.' },
  ],

  cronometer: [
    {
      id: 1,
      name: 'Normal',
      message:
        "Nesta opção, ao inserir uma ocorrência de jogo (gol, falta, etc) você deve registrar na súmula o mesmo valor do cronômetro do jogo (min/seg)! (Ex: o cronômetro inicia em '00:00' e houve um gol aos '05:30', na súmula será registrado um gol aos '05:30' da partida.",
    },
    {
      id: 2,
      name: 'Contagem Regressiva',
      message:
        "Nesta opção, ao inserir uma ocorrência de jogo (gol, falta, etc) você deve registrar na súmula o mesmo valor do cronômetro do jogo (min/seg)! O sistema fará o cálculo automaticamente para você. (Ex: se o cronômetro inicia em '20:00' e houve um gol aos '18:30', na súmula será registrado um gol aos '01:30' da partida.",
    },
  ],

  ranges: [
    '\ud83c[\udf00-\udfff]', // U+1F300 to U+1F3FF
    '\ud83d[\udc00-\ude4f]', // U+1F400 to U+1F64F
    '\ud83d[\ude80-\udeff]', // U+1F680 to U+1F6FF
  ],

  playerHeadCells: [
    {
      key: 'nickName',
      id: 'nickName',
      label: 'Apelido',
      className: 'nicknamecell',
    },
    {
      key: 'documentation',
      id: 'documentation',
      label: '',
      className: 'docscell',
    },
    { key: 'name', id: 'name', label: 'Nome', className: 'namecell' },
    { key: 'defaultNumber', id: 'number', label: 'N°', className: 'number' },
    {
      key: 'position',
      id: 'position',
      label: 'Posição',
      className: 'position',
    },
    {
      key: 'isStarter',
      id: 'isStarter',
      label: 'Titular',
      className: 'radio',
    },
    {
      key: 'isReserve',
      id: 'isReserve',
      label: 'Reserva',
      className: 'radio',
    },
    {
      key: 'isCaptain',
      id: 'isCaptain',
      label: 'Capitão',
      className: 'radio',
    },
  ],

  technicalHeadCells: [
    {
      key: 'nickName',
      id: 'nickName',
      label: 'Apelido',
      className: 'nicknamecell',
    },
    {
      key: 'documentation',
      id: 'documentation',
      label: '',
      className: 'docscell',
    },
    { key: 'name', id: 'name', label: 'Nome', className: 'namecell' },
    {
      key: 'technicalFunction',
      id: 'technicalFunction',
      label: 'Função',
      className: 'technicalFunction',
    },
    {
      key: 'isStarter',
      id: 'isStarter',
      label: 'Escalar',
      className: 'checkColumn',
    },
  ],

  timeType: [
    {
      id: 1,
      name: 'Regular',
    },
    {
      id: 2,
      name: 'Prorrogação',
    },
    {
      id: 3,
      name: 'Pênaltis',
    },
  ],

  gameTimeEvents: [
    {
      id: 1,
      name: '1° Tempo',
    },
    {
      id: 2,
      name: '2° Tempo',
    },
    {
      id: 3,
      name: '3° Tempo',
    },
    {
      id: 4,
      name: '4° Tempo',
    },
  ],

  occurrenceTypes: [
    {
      id: 1,
      name: 'Início',
    },
    {
      id: 2,
      name: 'Fim',
    },
    {
      id: 3,
      name: 'Jogo Encerrado',
    },
    {
      id: 4,
      name: 'Gol',
    },
    {
      id: 5,
      name: 'Gol de Falta',
    },
    {
      id: 6,
      name: 'Gol de tiro livre',
    },
    {
      id: 7,
      name: 'Gol de Pênalti',
    },
    {
      id: 8,
      name: 'Gol Contra',
    },
    {
      id: 9,
      name: 'Cartão Amarelo',
    },
    {
      id: 10,
      name: 'Cartão Vermelho',
    },
    {
      id: 11,
      name: 'Cartão Azul',
    },
    {
      id: 12,
      name: 'Falta + CA',
    },
    {
      id: 13,
      name: 'Falta + CV',
    },
    {
      id: 14,
      name: 'CA + CV',
    },
    {
      id: 15,
      name: 'Falta',
    },
    {
      id: 16,
      name: 'Pênalti defendido',
    },
    {
      id: 17,
      name: 'Tiro livre defendido',
    },
    {
      id: 18,
      name: 'Falta Obstrução',
    },
    {
      id: 19,
      name: 'Falta jogo perigoso',
    },
    {
      id: 20,
      name: 'Pedido de Tempo',
    },
    {
      id: 21,
      name: 'Sai',
    },
    {
      id: 22,
      name: 'Entra',
    },
    {
      id: 23,
      name: 'Assistência',
    },
    {
      id: 24,
      name: 'Concede Captania',
    },
    {
      id: 25,
      name: 'Assume Captania',
    },
    {
      id: 26,
      name: 'MVP',
    },
  ],

  // MESSAGES

  ADDPLAYER: 'Atleta(s) adicionado(s) com sucesso.',
  ADDTECHNICAL: 'Membro(s) adicionado(s) com sucesso.',
  GENERICERROR:
    'Houve um erro ao atualizar as informações, por favor tente novamente.',
  LOGINERROR:
    'Não foi possível acessar a Súmula. Por favor verifique os dados de acesso utilizados.',
  GOALKEEPERERROR:
    'Não foi possível prosseguir, pois existe mais de um goleiro escalado como titular.',
  PLAYERNUMBERERROR:
    'Não foi possível prosseguir, pois existe jogadores escalados utilizando o mesmo número de camisa.',
  HASNONUMBERERROR:
    'Não foi possível prosseguir, pois existe jogador(es) escalado(s) sem número de camisa.',
  HASNOCAPTAINERROR:
    'Não foi possível prosseguir, pois não há um capitão na escalação.',
  HASNOGOALKEEPER:
    'Não foi possível prosseguir, pois não há um goleiro na escalação titular.',
  HASNOCRONOMETER:
    'Não foi possível prosseguir, pois não foi configurado um cronômetro regressivo porém não foi informado o tempo inicial.',
  CANNOTGOTOSUMULA:
    'Antes de prosseguir para a Súmula, por favor verifique se ambos os times estão com as escalações preenchidas corretamente.',
};
